import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiTicketActionItems, ServiceTicketActionItems } from 'services/ticket-action-items';
import * as dynamic from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { convertToDate } from '../../utils/dates';
import { selectAppUserID } from '../auth';
import { AppAsyncThunkConfig } from '../index';
import { ICheckDoneNotCompletedResponseItem } from './helpers';
import {
  selectCheckDoneNotCompletedDataFullMap,
  selectCheckDoneNotCompletedFilters,
} from './selectors';

const SELECT_TICKET_ITEMS = dynamic.select(
  'id',
  'rowIndex',
  'title',

  'done',
  'review',
  'itemKey',

  'reporterUserCrmProfileID',
  'assigneeUserCrmProfileID',
  'priorityID',
  'ticketActionItemStatusID',

  'startDateAndTime',
  'endDateAndTime',

  'ticketID',
  'pinned',

  `timeTrackings.Select(t => 
    new {
        t.duration,
        t.date
    }) as tracking`,
);

export const actionCheckDoneNotCompletedFetchAll = createAsyncThunk<
  ICheckDoneNotCompletedResponseItem[],
  void,
  AppAsyncThunkConfig
>('CHECK_DONE_NOT_COMPLETED/actionCheckDoneNotCompletedFetchAll', async (_, { getState }) => {
  try {
    const appUserID = selectAppUserID(getState());
    const { dateRange } = selectCheckDoneNotCompletedFilters(getState());
    const params = {
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter('isActive', true, dynamic.equals),
          dynamic.makeFilter('done', false, dynamic.equals),
          `(startDateAndTime <= DateTime("${convertToDate(
            dateRange[1],
          ).toISOString()}") && DateTime("${convertToDate(
            dateRange[0],
          ).toISOString()}") <= endDateAndTime)`,
          dynamic
            .mergeFilters(
              dynamic.makeFilter('ticket.isPrivate', false, dynamic.equals),
              dynamic.makeFilter('ticket.ownerUserCrmProfileID', appUserID, dynamic.equals),
            )
            .join('||'),
        )
        .join('&&'),
      select: SELECT_TICKET_ITEMS,
      orderBy: 'rowIndex asc',
    };
    const result = await ServiceTicketActionItems.getAllDynamic<
      ICheckDoneNotCompletedResponseItem,
      typeof params
    >(params);
    return result.data.value;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCheckDoneNotCompletedFetchItem = createAsyncThunk<
  ICheckDoneNotCompletedResponseItem,
  string,
  AppAsyncThunkConfig
>('CHECK_DONE_NOT_COMPLETED/actionCheckDoneNotCompletedFetchItem', async (id) => {
  try {
    const params = {
      select: SELECT_TICKET_ITEMS,
    };
    const result = await ServiceTicketActionItems.getDynamic<
      ICheckDoneNotCompletedResponseItem,
      typeof params
    >(id, params);
    return result.data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCheckDoneNotCompletedMove = createAsyncThunk<
  void,
  {
    id: string;
    from: { ticketActionItemStatusID: string; index: number };
    to: { ticketActionItemStatusID: string; index: number };
  },
  AppAsyncThunkConfig
>(
  'CHECK_DONE_NOT_COMPLETED/actionCheckDoneNotCompletedMove',
  async (input, { dispatch, getState }) => {
    try {
      const { id, from, to } = input;

      const ticketActionItemsMap = selectCheckDoneNotCompletedDataFullMap(getState());

      if (from.ticketActionItemStatusID !== to.ticketActionItemStatusID) {
        const ticketID = ticketActionItemsMap[id]?.ticketID;
        dispatch(
          apiTicketActionItems.endpoints.patchTicketActionItem.initiate({
            id,
            ticketActionItemStatusID: to.ticketActionItemStatusID,
            ticketID,
          }),
        );
      }
      return undefined;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
