import clsx from 'clsx';
import { memo } from 'react';
import { NoteDropMenu } from '../../../note-drop-menu';
import { CalendarNoteType } from '../data-provider';
import { NoteDescriptionTooltip } from '../note-description-tooltip';
import style from './index.module.scss';

interface Props {
  data: CalendarNoteType;
  isDueDate?: boolean;
}

export const CustomerGroupNote = memo<Props>(({ data }) => {
  return (
    <NoteDescriptionTooltip data={data}>
      <div className={clsx(style.root, data?.description && style.hasContent)}>
        <div className={style.title}>{data.title || ''}</div>
        <NoteDropMenu data={data} className={style.dropMenu} />
      </div>
    </NoteDescriptionTooltip>
  );
});
