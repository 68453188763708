import { NativeCustomScroll } from 'components/native-custom-scroll';
import { format } from 'date-fns';
import { memo, useMemo } from 'react';
import { useCalendarData } from '../data-provider';
import { Day } from '../day';
import style from './index.module.scss';

export const CalendarGrid = memo(() => {
  const { days } = useCalendarData();

  const daysList = useMemo(() => days.slice(0, 7).map((day) => format(day.date, 'EEE')), [days]);

  return (
    <NativeCustomScroll mode={'always'} className={style.root}>
      <div className={style.root}>
        <div className={style.header}>
          {daysList.map((day) => (
            <div key={day} className={style.day}>
              {day}
            </div>
          ))}
        </div>
        <div className={style.body}>
          {days.map((day) => (
            <div key={day.date.toString()} className={style.day}>
              <Day data={day} />
            </div>
          ))}
        </div>
      </div>
    </NativeCustomScroll>
  );
});
