import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TodayIcon from '@mui/icons-material/Today';
import { IconButton, IconButtonProps, Popover, Stack } from '@mui/material';
import { AppInput, AppInputProps } from 'components/app-input';
import { addDays, endOfMonth, format, isSameDay, startOfMonth } from 'date-fns';
import { useMergeRef, useOpen } from 'hooks';
import React, { ForwardedRef, forwardRef, memo, useCallback, useMemo, useRef } from 'react';
import variables from 'styles/config.scss';
import { convertToDate, isDateLike } from 'utils/dates';
import { composeFunctions } from 'utils/other';
import {
  CalendarRangePicker,
  CalendarRangePickerProps,
  strategyCalendarPickerWeekly,
} from '../calendar-range-picker';

const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy';

interface IStrategyButton {
  (direction: 'next' | 'prev', currentValue: any[]): Date[];
}

export const strategyButtonWeekly: IStrategyButton = (direction, currentValue) => {
  let start = currentValue[0];

  if (!start) {
    return [];
  }
  start = convertToDate(start);

  let end = currentValue[1]
    ? convertToDate(currentValue[1])
    : addDays(convertToDate(currentValue[0]), 7);

  const diffInDays = 7;

  if (direction === 'next') {
    return [addDays(start, diffInDays), addDays(end, diffInDays)];
  } else if (direction === 'prev') {
    return [addDays(start, -diffInDays), addDays(end, -diffInDays)];
  }

  return [];
};
export const strategyButtonMonthly: IStrategyButton = (direction, currentValue) => {
  let start = currentValue[0];

  if (!start) {
    return [];
  }
  start = convertToDate(start);
  const month = startOfMonth(
    addDays(
      direction === 'next' ? endOfMonth(start) : startOfMonth(start),
      direction === 'next' ? 1 : -1,
    ),
  );
  return [month, endOfMonth(month)];
};

interface Classes {
  root: string;
}

interface Props {
  value: any[];
  onChange: (value: Date[]) => void;
  onApply?: (value: Date[]) => void;
  closeOnApply?: boolean;
  className?: string;
  classes?: Partial<Classes>;
  inputFormat?: string;
  inputDivider?: string;
  isOpenPicker?: boolean;
  CalendarProps?: Partial<Omit<CalendarRangePickerProps, 'value' | 'onChange'>>;
  NextButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>>;
  PrevButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>>;
  strategyButton?: IStrategyButton;
  isMonthFormat?: boolean;
}

const Component = (
  {
    value: originValue,
    inputFormat = DEFAULT_INPUT_FORMAT,
    inputDivider = ' - ',
    onChange,
    closeOnApply = true,
    onApply,
    isOpenPicker,
    CalendarProps,
    NextButtonProps,
    PrevButtonProps,
    strategyButton = strategyButtonWeekly,
    isMonthFormat,
    ...rest
  }: Props & Omit<AppInputProps, 'value' | 'onChange' | 'onClick'>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const refTextField = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useOpen(isOpenPicker);

  const inputValue = useMemo(() => {
    if (
      originValue.length === 2 &&
      isSameDay(convertToDate(originValue[0]), convertToDate(originValue[1]))
    ) {
      return format(convertToDate(originValue[0]), inputFormat);
    }
    if (isMonthFormat) {
      const date = new Date(originValue[0]);
      return format(date, inputFormat);
    }
    return originValue
      .filter(isDateLike)
      .map((v) => format(convertToDate(v), inputFormat))
      .join(inputDivider);
  }, [originValue, inputFormat, inputDivider, isMonthFormat]);

  const checkApply = useCallback(
    (value: Date[]) => {
      if (value.length === 2) {
        onApply && onApply(value);
        closeOnApply && onClose();
      }
    },
    [onApply, onClose, closeOnApply],
  );

  const onClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange([]);
      onApply && onApply([]);
    },
    [onChange, onApply],
  );

  const factoryClickButton = useCallback(
    (direction: 'next' | 'prev') => {
      return () => {
        const _value = strategyButton(direction, originValue);
        onChange(_value);
        checkApply(_value);
      };
    },
    [originValue, strategyButton, checkApply, onChange],
  );

  return (
    <>
      <Stack direction={'row'} spacing={0.1} alignItems={'flex-end'} justifyContent={'center'}>
        <IconButton
          size={'small'}
          children={<ArrowBackIosNewIcon />}
          {...PrevButtonProps}
          onClick={factoryClickButton('prev')}
        />
        <AppInput
          variant={'standard'}
          value={inputValue}
          fullWidth={true}
          sx={{ minWidth: '22.6rem' }}
          {...rest}
          onClick={rest.disabled ? undefined : onOpen}
          inputRef={useMergeRef(ref, refTextField)}
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={rest.disabled}
                size={'small'}
                sx={{ margin: '-0.8rem 0', color: variables.colorGrey }}
              >
                <TodayIcon />
              </IconButton>
            ),
            ...rest.InputProps,
          }}
          onClear={composeFunctions(onClear, rest.onClear)}
        />
        <IconButton
          size={'small'}
          children={<ArrowForwardIosIcon />}
          {...NextButtonProps}
          onClick={factoryClickButton('next')}
        />
      </Stack>
      <Popover
        anchorEl={refTextField.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CalendarRangePicker
          value={originValue}
          onChange={composeFunctions(onChange, checkApply)}
          pickerStrategy={strategyCalendarPickerWeekly}
          isMonthFormat={isMonthFormat}
          {...CalendarProps}
        />
      </Popover>
    </>
  );
};

export const DateRangeStaticPicker = memo(forwardRef(Component)) as typeof Component;
