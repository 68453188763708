import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Stack, Typography } from '@mui/material';
import { Item, MenuButton } from 'components/menu-button';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionsNotes, selectDataExpired } from 'store/notes';
import style from './index.module.scss';

export const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const rowsExpired = useAppSelector(selectDataExpired);
  const hasExpired = rowsExpired.length > 0;
  const onOpenArchiveManager = useCallback(() => {
    dispatch(actionsNotes.setExpiredManager(true));
  }, [dispatch]);
  const handleArchive = useCallback(() => {
    navigate(APP_ROUTES.NOTES_ARCHIVE.path);
  }, [navigate]);
  const menuItems = useMemo<Item[]>(
    () => [
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <ArchiveOutlinedIcon
              fontSize={'inherit'}
              sx={{ marginRight: '0.6rem', fontSize: '1.8rem' }}
            />
            <Typography>{'notes-archive-manager'}</Typography>
          </Stack>
        ),
        onClick: onOpenArchiveManager,
        disabled: !hasExpired,
      },
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <ArchiveOutlinedIcon
              fontSize={'inherit'}
              sx={{ marginRight: '0.6rem', fontSize: '1.8rem' }}
            />
            <Typography>{'archive'}</Typography>
          </Stack>
        ),
        onClick: handleArchive,
      },
    ],
    [handleArchive, onOpenArchiveManager, hasExpired],
  );

  return (
    <>
      <MenuButton items={menuItems}>
        <IconButton className={style.root}>
          <SettingsIcon />
        </IconButton>
      </MenuButton>
    </>
  );
};
