import clsx from 'clsx';
import { format, startOfDay } from 'date-fns';
import React, { memo, useMemo } from 'react';
import { NoteDropMenu } from '../../../note-drop-menu';
import { NoteType } from '../../index';
import { useMarks } from '../marks-provider';
import style from './index.module.scss';
import EventIcon from '@mui/icons-material/Event';
import { useTranslate } from '../../../../../../hooks';

interface Props {
  data: NoteType;
  className?: string;
  style?: React.CSSProperties;
  setHover: (hover: boolean) => void;
  ownerState: {
    dragging: boolean;
    value: number[];
  };
}

export const Note = memo<Props>(
  ({ data, style: trackStyle, setHover, ownerState: { dragging, value } }) => {
    const {tp} = useTranslate();
    const { marksMap, marks } = useMarks();

    const customer = useMemo(() => data._customer?.title || tp('without-customer'), [data._customer, tp]);
    const { isBefore, isAfter, start, end, isExpired } = useMemo(() => {
      const _start = marksMap[value[0]];
      const _end = marksMap[value[1]];
      return {
        isBefore: value[0] === 0,
        isAfter: _end ? _end.value === marks[marks.length - 1].value : false,
        start: _start ? format(_start.date, 'd MMM') : '',
        end: _end ? format(_end.date, 'd MMM') : '',
        isExpired: _end ? startOfDay(new Date()).getTime() >= marksMap[value[1]].date : false,
      };
    }, [value, marks, marksMap]);

    return (
      <div
        style={trackStyle}
        className={clsx(
          style.root,
          isExpired && style.expired,
          isBefore && style.before,
          isAfter && style.after,
          dragging && style.dragging,
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={style.inner}>
          <div className={style.bg} />
          <NoteDropMenu data={data} />
          {customer && <div className={style.customer}>{customer}</div>}
          <div className={style.date}>
            <EventIcon className={style.icon}/>
            <span>{start}</span>
            <span className={style.dash}>&mdash;</span>
            <span>{end}</span>
          </div>
          <div className={style.title}>
            <span>{data.title || ''}</span>
          </div>
        </div>
      </div>
    );
  },
);
