import { eachDayOfInterval } from 'date-fns';
import { useAppSelector } from 'hooks';
import React, { createContext, useContext, useMemo } from 'react';
import {
  selectCalendarFilters,
  selectDataFullFilteredForCalendar,
  selectDataFullFilteredForTimeLine,
} from 'store/notes/selectors';
import { getCalendarMonthBounds } from 'utils/dates';

type NotesDataFullType = ReturnType<typeof selectDataFullFilteredForTimeLine>;
export type CalendarNoteType = NotesDataFullType[number];

export interface ICalendarDay {
  date: Date;
  notes: CalendarNoteType[];
}

export interface CalendarDataContextProps {
  days: ICalendarDay[];
  daysMap: Record<string, ICalendarDay>;
}

const CalendarDataContext = createContext<CalendarDataContextProps | undefined>(undefined);

export const CalendarDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const data = useAppSelector(selectDataFullFilteredForCalendar);
  const { dates } = useAppSelector(selectCalendarFilters);

  const value = useMemo(() => {
    const [start, end] = getCalendarMonthBounds(dates[0]);
    const { days, daysMap } = eachDayOfInterval({ start, end }).reduce(
      (acc, date) => {
        const key = date.toISOString();
        acc.days.push((acc.daysMap[key] = { date, notes: [] }));
        return acc;
      },
      { days: [] as ICalendarDay[], daysMap: {} as Record<string, ICalendarDay> },
    );

    data.forEach((note) => {
      const interval = eachDayOfInterval({
        start: new Date(note.entryDate),
        end: new Date(note.dueDate),
      });

      interval.forEach((date) => {
        const noteDate = date.toISOString();
        if (daysMap[noteDate]) {
          daysMap[noteDate].notes.push(note);
        }
      });
    });

    return { days, daysMap };
  }, [data, dates]);

  return (
    <CalendarDataContext.Provider value={value}>
      {value.days.length > 0 && children}
    </CalendarDataContext.Provider>
  );
};

export const useCalendarData = () => {
  const context = useContext(CalendarDataContext);
  if (!context) {
    throw new Error('useCalendarData must be used within a CalendarDataProvider');
  }
  return context;
};
