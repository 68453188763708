import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { useOpen } from 'hooks';
import React, { useCallback, useMemo, useRef } from 'react';

export interface Item extends Partial<Omit<MenuItemProps<any>, 'title'>> {
  title: string | React.ReactNode;
}

export interface MenuButtonProps {
  items: Item[];
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  MenuProps?: Partial<MenuProps>;
  MenuItemProps?: Partial<MenuItemProps>;
  closeOnClick?: boolean;
  isPreventDefault?: boolean;
  children: React.ReactNode;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  items,
  isOpen: _isOpen,
  onClose: _onClose,
  onOpen: _onOpen,
  MenuProps,
  MenuItemProps,
  closeOnClick = true,
  isPreventDefault = true,
  children,
}) => {
  const { isOpen: innerIsOpen, onOpen: innerOnOpen, onClose: innerOnClose } = useOpen();
  const isOpen = _isOpen === undefined ? innerIsOpen : _isOpen;
  const onClose = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      if (isPreventDefault) {
        e.stopPropagation();
        e.preventDefault();
      }
      _onClose === undefined ? innerOnClose() : _onClose();
    },
    [_onClose, innerOnClose, isPreventDefault],
  );
  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      if (isPreventDefault) {
        e.stopPropagation();
        e.preventDefault();
      }
      _onOpen === undefined ? innerOnOpen() : _onOpen();
    },
    [_onOpen, innerOnOpen, isPreventDefault],
  );

  const ref = useRef<any>();
  const factoryClick = useCallback(
    (handle: Item['onClick']) => {
      return (e: React.MouseEvent<HTMLLIElement>) => {
        handle && handle(e);
        if (closeOnClick) {
          onClose(e);
        }
      };
    },
    [closeOnClick, onClose],
  );
  const Child = useMemo(() => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // @ts-ignore
        return React.cloneElement(child, { ref, onClick: onOpen });
      }
      return child;
    });
  }, [children, ref, onOpen]);

  return (
    <>
      {Child}
      <Menu
        anchorEl={ref.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...MenuProps}
        MenuListProps={{
          sx: {
            padding: '1.4rem 0.8rem',
            ...MenuProps?.sx,
          },
        }}
      >
        {items.map(({ title, onClick, ...rest }, i) => (
          <MenuItem
            key={i}
            {...rest}
            onClick={factoryClick(onClick)}
            {...MenuItemProps}
            sx={{
              padding: '0.1rem 1.2rem',
              paddingRight: '1.2rem',
              textTransform: 'capitalize',
              borderRadius: '0.4rem',
              minHeight: '3.2rem !important',
              display: 'grid',
              alignItems: 'center',
              ...MenuItemProps?.sx,
              ...rest.sx,
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
