import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { Unset } from 'utils/types';
import style from './index.module.scss';

interface Props extends TypographyProps {
  className?: string;
  html: Unset;
}

export const DescriptionContent: React.FC<Props> = ({ className, html, ...rest }) => {
  return (
    <Typography
      variant={'body2'}
      component={'div'}
      className={clsx(style.root, className)}
      dangerouslySetInnerHTML={{ __html: html || '' }}
      {...rest}
    />
  );
};
