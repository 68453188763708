import clsx from 'clsx';
import { DateRange } from 'pages/notes/components/date-range';
import { memo } from 'react';
import { CalendarNoteWithDueToday } from './index';
import style from './index.module.scss';

interface Props {
  data: CalendarNoteWithDueToday;
}

export const Note = memo<Props>(({ data }) => {
  return (
    <div className={clsx(style.note, data.isDueToday && style.dueDate)}>
      <div className={style.conent}>
        {data.isDueToday && (
          <DateRange
            entryDate={data.entryDate}
            dueDate={data.dueDate}
            archiveDate={data._archiveDate}
          />
        )}
        {data.title || ''}
      </div>
    </div>
  );
});
