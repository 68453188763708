import { addDays, eachDayOfInterval, startOfDay, subDays } from 'date-fns';
import { useAppSelector } from 'hooks';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { selectTimeLineFilters } from 'store/notes';
import style from './index.module.scss';

const PADDING = 64;

export interface Mark {
  value: number;
  date: number;
  label?: string;
}

export interface MarksContextProps {
  width: number;
  marks: Mark[];
  marksMap: Record<number, Mark>;
  dates: number[];
}

const MarksContext = createContext<MarksContextProps | undefined>(undefined);

export const MarksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { dates: Dates } = useAppSelector(selectTimeLineFilters);
  const dates = useMemo(
    () =>
      eachDayOfInterval({ start: Dates[0], end: Dates[1] }).map((date) =>
        startOfDay(date).getTime(),
      ),
    [Dates],
  );

  const [width, setWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (wrapperRef.current) {
        setWidth(wrapperRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const { marks, marksMap } = useMemo(() => {
    const before = subDays(dates[0], 1).getTime();
    const after = addDays(dates[dates.length - 1], 1).getTime();
    const baseMarks = [
      { value: 0, date: before },
      { value: PADDING, date: dates[0] },
      { value: width - PADDING, date: dates[dates.length - 1] },
      { value: width, date: after },
    ];

    const additionalMarks = Array.from({ length: dates.length - 1 }, (_, i) => {
      const value = PADDING + ((i + 1) * (width - PADDING * 2)) / dates.length;
      return { value, date: dates[i + 1] };
    });

    return [...baseMarks.slice(0, 2), ...additionalMarks, ...baseMarks.slice(2)].reduce(
      (acc, mark) => {
        acc.marks.push((acc.marksMap[mark.value] = mark));
        return acc;
      },
      { marks: [] as MarksContextProps['marks'], marksMap: {} as MarksContextProps['marksMap'] },
    );
  }, [width, dates]);

  return (
    <MarksContext.Provider value={{ width, marks, marksMap, dates }}>
      <div ref={wrapperRef} className={style.root}>
        {marks.length > 0 && children}
      </div>
    </MarksContext.Provider>
  );
};

export const useMarks = () => {
  const context = useContext(MarksContext);
  if (!context) {
    throw new Error('useWidth must be used within a WidthProvider');
  }
  return context;
};
