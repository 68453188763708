import { memo } from 'react';
import { CalendarNoteType } from '../data-provider';
import { DueDateNotesGroupNote } from '../due-date-notes-group-note';

interface Props {
  data: CalendarNoteType[];
  title: string;
}

export const DueDateNotesGroup = memo<Props>(({ data, title }) => {
  return (
    <>
      {data.map((note) => (
        <DueDateNotesGroupNote key={note.id} data={note} />
      ))}
    </>
  );
});
