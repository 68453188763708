import { DateValue, isDateLike } from 'utils/dates';
import { DynamicOrder } from 'utils/dynamic';
import { Unset } from 'utils/types';
import * as yup from 'yup';
import { InferType } from 'yup';
import { BaseImage, schemaBaseImage } from '../@shared/images';

const API = 'Notes';

export const API_NOTES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<INote, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<INote, 'id'>) => `${API}/Delete/${data.id}`,
};

export const schemaNote = yup.object().shape({
  id: yup.string().required('rule-required').default(undefined),
  userCrmProfileID: yup.string().required('rule-required').default(undefined),
  title: yup.string().notRequired().default(''),
  customerID: yup.string().notRequired().default(undefined),
  ticketActionItemID: yup.string().notRequired().default(undefined),
  description: yup.string().notRequired().default(''),
  entryDate: yup
    .mixed<Date | string>()
    .required('rule-required')
    .test('date-test', 'rule-date', isDateLike)
    .default(null),
  dueDate: yup
    .mixed<Date | string>()
    .required('rule-required')
    .test('date-test', 'rule-date', isDateLike)
    .default(null),
  isActive: yup.boolean().notRequired().default(true),
});

export type INote = InferType<typeof schemaNote>;

export const schemaNotePost = schemaNote
  .pick(['customerID', 'title', 'description', 'userCrmProfileID', 'entryDate', 'dueDate'])
  .shape({
    images: yup.array().of(schemaBaseImage),
  })
  .test('at-least-one-field', 'rule-required-at-least-one', (value) => {
    return Boolean(value.title || value.description);
  });

export type NotePostInput = InferType<typeof schemaNotePost>;

export const schemaNotePatch = schemaNotePost.concat(schemaNote.pick(['id']));

export type NotePatchInput = InferType<typeof schemaNotePatch>;

export type NoteDeleteInput = Pick<INote, 'id'>;
export type NoteDoneInput = Pick<INote, 'id' | 'ticketActionItemID'>;
export type NotePatchDates = Pick<INote, 'id' | 'entryDate' | 'dueDate'>;

export type NoteToggleArchiveInput = Pick<INote, 'id'> & { value: boolean };

export enum NOTE_CUSTOMER {
  WITHOUT_CUSTOMER = 1,
  ALL = 2,
}

export interface AppNote
  extends Pick<
    INote,
    | 'id'
    | 'title'
    | 'description'
    | 'userCrmProfileID'
    | 'customerID'
    | 'isActive'
    | 'entryDate'
    | 'dueDate'
    | 'ticketActionItemID'
  > {
  rowIndex: number;
  images: BaseImage[];
  itemKey: string | null;
}
export interface IBoardNote extends AppNote {
  createdDate: DateValue;
}
export interface IBoardNotesArgs {
  search: string;
  dateRange: DateValue[];
  customer: string | NOTE_CUSTOMER;
  appUserID: string;
}

export interface IGridNote extends AppNote {
  companyName: Components.Schemas.Customer['companyName'];
}
export interface IGridNotesArgs {
  search: string;
  dateRange: DateValue[];
  customer: string | NOTE_CUSTOMER;
  appUserID: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}

export type DueDateManageInput = {
  id: string;
  days: Unset<number>;
};
