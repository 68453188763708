import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { ButtonAdd } from 'components/buttons';
import { useAppDispatch, useTranslate } from 'hooks';
import { memo, useCallback } from 'react';
import { actionsNotes } from 'store/notes';
import { Settings } from '../settings';

export const MainControls = memo(() => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const onNew = useCallback(() => {
    dispatch(actionsNotes.setAddNewNote({}));
  }, [dispatch]);

  return (
    <Box flexShrink={0}>
      <Box flexShrink={0} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} />
      <Stack direction={'row'} spacing={1} mt={0.3} alignItems={'flex-end'}>
        <Box flexShrink={0}>
          <ButtonAdd onClick={onNew}>{tp('add-new-note')}</ButtonAdd>
        </Box>
        <Box flexShrink={0}>
          <Settings />
        </Box>
      </Stack>
    </Box>
  );
});
