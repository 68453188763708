import { useAppDispatch, useAppSelector, useEffectNotifyError } from 'hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { apiNotes } from 'services/notes';
import { actionsNotes, selectDataExpired, selectNotesStatuses } from 'store/notes';
import { isFulfilledMutation } from 'utils/service';
import { Unset } from 'utils/types';
import { DialogExpiredNotes } from '../dialog-expired-notes';

const useUpdateNotes = apiNotes.useManageNotesDueDateMutation;

const SESSION_KEY = 'NOTES_ARCHIVE_EXPIRED_INIT';

interface Props {}
const Component: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const expiredRows = useAppSelector(selectDataExpired);

  const [updateNotes, resultUpdate] = useUpdateNotes();
  useEffectNotifyError(resultUpdate.error);

  const items = useMemo(() => {
    return expiredRows.map((row) => {
      return {
        id: row.id,
        title: row.title,
        customer: row._customer,
        description: row.description,
      };
    });
  }, [expiredRows]);

  const onClose = useCallback(() => {
    dispatch(actionsNotes.setExpiredManager(false));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (rows: { id: string; days: Unset<number> }[]) => {
      const res = await updateNotes(rows);

      if (isFulfilledMutation(res)) {
        onClose();
      }
    },
    [updateNotes, onClose],
  );

  const isLoading = resultUpdate.isLoading;

  return (
    <DialogExpiredNotes isLoading={isLoading} items={items} onClose={onClose} onSubmit={onSubmit} />
  );
};

export const DialogExpiredNotesContainer: React.FC<Props> = () => {
  const expiredRows = useAppSelector(selectDataExpired);
  const { isOpenExpiredManager } = useAppSelector(selectNotesStatuses);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!expiredRows.length) return;
    const isInit = sessionStorage.getItem(SESSION_KEY);

    if (isInit) return;

    dispatch(actionsNotes.setExpiredManager(true));
    sessionStorage.setItem(SESSION_KEY, 'true');
  }, [expiredRows, dispatch]);

  return isOpenExpiredManager ? <Component /> : null;
};
