import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonBase, Collapse } from '@mui/material';
import { TranslateMap } from 'configs';
import { useOpen, useTranslate } from 'hooks';
import React, { memo } from 'react';
import style from './index.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

export const RightSideCollapse = memo<Props>(({ title, children }) => {
  const { tp } = useTranslate();

  const { isOpen, onToggle } = useOpen(true);
  return (
    <div className={style.root}>
      <ButtonBase className={style.btnCollapse} onClick={onToggle}>
        <div className={style.title}>{tp(title as keyof TranslateMap)}</div>
        <div className={style.line} />
        {isOpen ? (
          <ExpandLessIcon className={style.icon} />
        ) : (
          <ExpandMoreIcon className={style.icon} />
        )}
      </ButtonBase>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div>{children}</div>
      </Collapse>
    </div>
  );
});
