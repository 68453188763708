import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Popover, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { ButtonColorful } from 'components/button-colorful';
import { ColorPickerCustomer } from 'components/color-picker-customer';
import { useAppDispatch, useEffectNotifyError, useOpen } from 'hooks';
import React, { useCallback, useRef } from 'react';
import { apiCustomers } from 'services/customers';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { actionsNotes } from '../../../../store/notes';
import style from './index.module.scss';

const useUpdateColor = apiCustomers.usePatchCustomerMutation;

interface ControlColorProps {
  customerID: string;
  color: string;
}

const ControlColor: React.FC<ControlColorProps> = ({ color, customerID }) => {
  const stateColor = useOpen();
  const ref = useRef<HTMLButtonElement>(null);

  const [update, resultUpdate] = useUpdateColor();
  useEffectNotifyError(resultUpdate.error);

  const onChange = useCallback(
    (hex: string) => {
      stateColor.onClose();
      update({ id: customerID, color: hex });
    },
    [stateColor, update, customerID],
  );

  return (
    <>
      <IconButton
        ref={ref}
        className={style.control}
        onClickCapture={composeFunctions(handleEventStopAndPrevent, stateColor.onOpen)}
        disabled={resultUpdate.isLoading}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={stateColor.isOpen}
        anchorEl={ref.current}
        onClose={composeFunctions(handleEventStopAndPrevent, stateColor.onClose)}
      >
        <ColorPickerCustomer value={color} onChange={onChange} />
      </Popover>
    </>
  );
};

interface ControlAssProps {
  customerID: string;
}

const ControlAdd: React.FC<ControlAssProps> = ({ customerID }) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(actionsNotes.setAddNewNote({ customerID }));
  }, [dispatch, customerID]);

  return (
    <>
      <IconButton
        className={style.control}
        onClickCapture={composeFunctions(handleEventStopAndPrevent, handleClick)}
      >
        <AddIcon />
      </IconButton>
    </>
  );
};

interface Props {
  color: string;
  onClick: () => void;
  disabled: boolean;
  isActive: boolean;
  isControls?: boolean;
  title: string;
  customerID?: string;
}

export const CustomerItem: React.FC<Props> = ({
  color,
  onClick,
  disabled,
  isActive,
  title,
  customerID,
}) => {
  return (
    <ButtonColorful
      bColor={color}
      disabled={disabled}
      isActive={isActive}
      className={clsx(style.root, isActive && style.rootActive)}
    >
      <Stack direction={'row'} width={'100%'}>
        <div
          onClick={onClick}
          style={{ flexGrow: 1, display: 'inline-flex', alignItems: 'center', minWidth: 0 }}
        >
          <Typography title={title} noWrap maxWidth={'99%'} pr={1}>
            {title}
          </Typography>
        </div>

        {!!customerID && (
          <Stack direction={'row'} alignItems={'center'} alignSelf={'center'}>
            <ControlAdd customerID={customerID} />
            <ControlColor customerID={customerID} color={color} />
          </Stack>
        )}
      </Stack>
    </ButtonColorful>
  );
};
