import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  value: number;
  color: string;
}
export const Progress: React.FC<Props> = ({ value, color, ...rest }) => {
  let width = useMemo(() => {
    return Math.max(Math.min(100, value), 0);
  }, [value]);
  return (
    <Box {...rest} className={clsx(style.root, rest.className)} sx={{ height: 3, ...rest.sx }}>
      <Box className={style.track} sx={{ backgroundColor: color }} />
      <Box className={style.line} sx={{ backgroundColor: color, width: `${width}%` }} />
    </Box>
  );
};
