import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { format, subDays } from 'date-fns';
import { useAppSelector, useTranslate } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  actionsNotes,
  selectCalendarSelectedDate,
  selectDataFullFilteredForCalendarToday,
} from 'store/notes';
import { CustomerGroup } from '../customer-group';
import { CalendarNoteType } from '../data-provider';
import { DueDateNotes } from '../due-date-notes';
import { RightSideCollapse } from '../right-side-collapse';
import style from './index.module.scss';

export const RightSide = memo(() => {
  const dispatch = useDispatch();
  const { tp } = useTranslate();

  const data = useAppSelector(selectDataFullFilteredForCalendarToday);
  const selectedDate = useAppSelector(selectCalendarSelectedDate);

  const [dayOfWeek, day, month] = useMemo(
    () => format(selectedDate, 'EEE d MMM').split(' '),
    [selectedDate],
  );

  const isToday = useMemo(
    () => format(selectedDate, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd'),
    [selectedDate],
  );
  const { notes, notesCount, dueDateNotes, dueDateNotesCount } = useMemo(() => {
    const currentDate = format(selectedDate, 'yyyy-MM-dd');
    return (data || []).reduce(
      (acc, note) => {
        const key = note?._customer?.title || tp('without-customer');
        const place =
          note.dueDate && format(note.dueDate, 'yyyy-MM-dd') === currentDate
            ? 'dueDateNotes'
            : 'notes';

        if (!acc[place][key]) acc[place][key] = [];
        acc[place][key].push(note);

        if (place === 'dueDateNotes') acc.dueDateNotesCount++;
        else acc.notesCount++;

        return acc;
      },
      { notes: {}, notesCount: 0, dueDateNotes: {}, dueDateNotesCount: 0 } as {
        notes: Record<string, CalendarNoteType[]>;
        notesCount: number;
        dueDateNotes: Record<string, CalendarNoteType[]>;
        dueDateNotesCount: number;
      },
    );
  }, [data, selectedDate, tp]);

  const handlePrevious = useCallback(() => {
    dispatch(actionsNotes.setSelectedDate(subDays(selectedDate, 1)));
  }, [dispatch, selectedDate]);

  const handleNext = useCallback(() => {
    dispatch(actionsNotes.setSelectedDate(subDays(selectedDate, -1)));
  }, [dispatch, selectedDate]);

  return (
    <NativeCustomScroll mode={'always'} className={style.root}>
      <div className={style.header}>
        <Button onClick={handlePrevious} size={'small'} className={style.btn}>
          <ChevronLeftIcon />
        </Button>
        <div className={clsx(style.title, isToday && style.today)}>
          <span>
            {dayOfWeek} <strong>{day}</strong> {month}
          </span>
        </div>
        <Button onClick={handleNext} size={'small'} className={style.btn}>
          <ChevronRightIcon />
        </Button>
      </div>
      <div className={style.notes}>
        {dueDateNotesCount > 0 && (
          <RightSideCollapse title={'due-date-notes'}>
            <DueDateNotes data={dueDateNotes} count={dueDateNotesCount} />
          </RightSideCollapse>
        )}
        {notesCount > 0 && (
          <RightSideCollapse title={'other-notes'}>
            {Object.entries(notes).map(([key, value]) => (
              <CustomerGroup key={key} data={value} title={key} open={true} />
            ))}
          </RightSideCollapse>
        )}
      </div>
    </NativeCustomScroll>
  );
});
