import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { CollapsePanel } from 'components/collapse-panel';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useAppSelector, useTranslate } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { actionsNotesLayout, selectNotesLayoutExpanded } from 'store/notes-layout';
import { MainControls } from '../../pages/notes/components';
import { actionsNotes, NOTES_VIEW, selectView } from '../../store/notes';
import { enumToArray } from '../../utils/types';
import { Customers } from './components';
import style from './index.module.scss';

export const NotesLayout = memo(() => {
  const { tp, t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const title = tp('notes');

  const { pathname } = useLocation();

  const isExpanded = useAppSelector(selectNotesLayoutExpanded);
  const onToggleExpand = useCallback(() => {
    dispatch(actionsNotesLayout.toggleExpanded());
  }, [dispatch]);

  const view = useAppSelector(selectView);
  const viewOptions = useMemo(() => {
    return enumToArray(NOTES_VIEW).map(({ id }: { id: NOTES_VIEW }) => {
      const title = t(id !== NOTES_VIEW.GRID ? id : 'post-it');
      return {
        id,
        title: title,
        icon: (() => {
          switch (id) {
            case NOTES_VIEW.CALENDAR:
              return <CalendarMonthIcon sx={{ fontSize: 20 }} />;
            case NOTES_VIEW.TIMELINE:
              return <ViewTimelineIcon sx={{ fontSize: 20 }} />;
            default:
              return <PushPinIcon sx={{ fontSize: 20 }} />;
          }
        })(),
      };
    }, []);
  }, [t]);
  const handleChangeView = useCallback(
    (value: NOTES_VIEW) => {
      dispatch(actionsNotes.setView(value));
    },
    [dispatch],
  );
  const handleNodes = useCallback(() => {
    navigate(APP_ROUTES.NOTES.path);
  }, [navigate]);

  return (
    <CollapsePanel
      in={isExpanded}
      onClick={onToggleExpand}
      panel={
        <div className={style.left}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
            <Typography variant={'h2'} component={'h1'} color={'primary'}>
              {title}
            </Typography>
          </Stack>

          <Customers isLoading={false} />
        </div>
      }
    >
      <div className={style.right}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {pathname === APP_ROUTES.NOTES.path && (
            <>
              <Tabs value={view}>
                {viewOptions.map(({ id, title, icon }) => (
                  <Tab
                    key={id}
                    value={id}
                    label={title}
                    onClick={() => handleChangeView(id)}
                    sx={{
                      minHeight: 48,
                    }}
                    icon={icon}
                    iconPosition={'start'}
                  />
                ))}
              </Tabs>
              <MainControls />
            </>
          )}
          {pathname === APP_ROUTES.NOTES_ARCHIVE.path && (
            <Button variant={'contained'} onClick={handleNodes} startIcon={<ChevronLeftIcon />}>
              {tp('notes')}
            </Button>
          )}
        </Stack>
        <Outlet />
      </div>
    </CollapsePanel>
  );
});
