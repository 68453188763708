import { Stack, Typography } from '@mui/material';
import { AppInput } from 'components/app-input';
import { useFieldProps, useTranslate } from 'hooks';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { GridModel } from '../../models';

interface Props {
  row: GridModel;
}
export const ColumnDays: React.FC<Props> = ({ row }) => {
  const { tp } = useTranslate();
  const { control } = useFormContext();

  const getFieldProps = useFieldProps();

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'} my={0.5}>
      <Controller
        control={control}
        name={row.id}
        render={(renderProps) => {
          const props = getFieldProps(renderProps);
          return (
            <AppInput
              {...props}
              onChange={(event) => {
                const val = event.target.value;
                const fixed = ['', null, undefined].some((v) => v === val) ? null : val;

                props.onChange(fixed);
              }}
              placeholder={'0'}
              helperText={props.helperText.trim()}
              label={null}
              size={'small'}
              variant={'outlined'}
              fullWidth={false}
              type={'number'}
              disableClearable
              sx={{ minWidth: '8rem' }}
            />
          );
        }}
      />
      <Typography color={'textSecondary'} textTransform={'capitalize'}>
        {tp('days')}
      </Typography>
    </Stack>
  );
};
