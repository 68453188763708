import { Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import { AppCheckbox } from 'components/app-checkbox';
import { ButtonColorful } from 'components/button-colorful';
import { Loading } from 'components/loading';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { useAppDispatch, useAppSelector, useSourceCustomers, useTranslate } from 'hooks';
import { CustomerItem } from 'layouts/notes/components/customer-item';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NOTE_CUSTOMER } from 'services/notes';
import { actionsNotesLayout, selectNotesLayoutFilters } from 'store/notes-layout';
import { isNoteCustomerID } from 'store/notes-layout/helpers';
import variables from 'styles/config.scss';
import { NOTE_DEFAULT_COLOR } from '../../helpers';
import style from './index.module.scss';

interface Props {
  isLoading: boolean;
}

export const Customers: React.FC<Props> = ({ isLoading }) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const [showInactive, setShowInactive] = useState(false);

  const { customer } = useAppSelector(selectNotesLayoutFilters);

  const onSelectCustomer = useCallback(
    (cus: { id: string }) => {
      dispatch(
        actionsNotesLayout.filtersSet({
          customer: cus.id === customer ? NOTE_CUSTOMER.ALL : cus.id,
        }),
      );
    },
    [dispatch, customer],
  );
  const factoryClickCustomer = useCallback(
    (customer: { id: string }) => {
      return () => {
        onSelectCustomer(customer);
      };
    },
    [onSelectCustomer],
  );
  const factoryClickNotCustomer = useCallback(
    (value: NOTE_CUSTOMER) => {
      return () => {
        dispatch(
          actionsNotesLayout.filtersSet({
            customer: value === customer ? NOTE_CUSTOMER.ALL : value,
          }),
        );
      };
    },
    [dispatch, customer],
  );

  const { data, isFetching } = useSourceCustomers();

  const customerOptions = useMemo(() => {
    return showInactive ? data : data.filter((customer) => customer.isActive);
  }, [data, showInactive]);

  useEffect(() => {
    if (showInactive) return;
    if (!isNoteCustomerID(customer)) return;
    const item = customerOptions.find(({ id }) => id === customer);

    if (item) return;

    dispatch(
      actionsNotesLayout.filtersSet({
        customer: NOTE_CUSTOMER.ALL,
      }),
    );
  }, [showInactive, customerOptions, customer, dispatch]);

  return (
    <div className={style.root}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        px={1.6}
        mb={1}
        justifyContent={'space-between'}
      >
        <Typography
          fontWeight={500}
          component={'h3'}
          textTransform={'uppercase'}
          letterSpacing={1.25}
          color={variables.colorGrey}
        >
          {tp('customer')}
        </Typography>
        <FormControlLabel
          control={<AppCheckbox />}
          label={tp('show-inactive')}
          labelPlacement={'start'}
          value={showInactive}
          onChange={(_, v) => setShowInactive(v)}
        />
      </Stack>

      <NativeCustomScroll mode={'always'}>
        <Stack spacing={0.4} px={1} pb={1}>
          <ButtonColorful
            bColor={variables.colorPrimary}
            disabled={isLoading}
            isActive={customer === NOTE_CUSTOMER.ALL}
            onClick={factoryClickNotCustomer(NOTE_CUSTOMER.ALL)}
          >
            {tp('all')}
          </ButtonColorful>
          {customerOptions.map((cus) => {
            const isActive = customer === cus.id;
            return (
              <CustomerItem
                key={cus.id}
                customerID={cus.id}
                color={cus.color}
                onClick={factoryClickCustomer(cus)}
                disabled={isLoading}
                isActive={isActive}
                title={cus.title}
              />
            );
          })}
        </Stack>
      </NativeCustomScroll>
      {isFetching && <Loading />}
      <Divider />
      <Stack spacing={1} direction={'row'} p={1}>
        <ButtonColorful
          bColor={NOTE_DEFAULT_COLOR}
          disabled={isLoading}
          isActive={customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER}
          onClick={factoryClickNotCustomer(NOTE_CUSTOMER.WITHOUT_CUSTOMER)}
        >
          {tp('without-customer')}
        </ButtonColorful>
      </Stack>
    </div>
  );
};
