import { Grid } from '@mui/material';
import { AppInputSearch } from 'components/app-input-search';
import {
  DateRangeStaticPicker,
  strategyButtonMonthly,
  strategyButtonWeekly,
  strategyCalendarPickerMonthly,
  strategyCalendarPickerWeekly,
} from 'components/date-picker-range';
import { useAppDispatch, useAppSelector, useFilterForm, useFilterInputProps } from 'hooks';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import {
  actionsNotes,
  DATE_RANGE_TYPE,
  NOTES_VIEW,
  selectNotesStatuses,
  selectTimeLineFilters,
} from 'store/notes';

interface Props {}

export const FiltersTimeLine: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(selectNotesStatuses);
  const defaultValues = useAppSelector(selectTimeLineFilters);
  const onUpdate = useCallback(
    (formValue: typeof defaultValues) => {
      dispatch(actionsNotes.filtersSet({ view: NOTES_VIEW.TIMELINE, filters: formValue }));
    },
    [dispatch],
  );

  const { control, onChangeItem } = useFilterForm({ defaultValues, onUpdate });

  const getInputProps = useFilterInputProps({ onChangeItem, disabled: isLoading });

  // const dateRangeTypeOptions = useMemo(
  //   () => enumToArray(DATE_RANGE_TYPE).map(({ id }) => ({ id, title: t(id) }), []),
  //   [t],
  // );

  // const handleChangeDateRangeType = useCallback(
  //   (e: DATE_RANGE_TYPE) => {
  //     let dates: [Date, Date];
  //     const midDate = addMilliseconds(
  //       defaultValues.dates[0],
  //       differenceInMilliseconds(defaultValues.dates[1], defaultValues.dates[0]) / 2,
  //     );
  //
  //     if (e === DATE_RANGE_TYPE.MONTH) {
  //       const start = startOfMonth(midDate);
  //       const end = endOfMonth(start);
  //       dates = [start, end];
  //     } else if (e === DATE_RANGE_TYPE.WEEK) {
  //       const start = startOfWeek(midDate);
  //       const end = endOfWeek(start);
  //       dates = [start, end];
  //     } else {
  //       dates = defaultValues.dates;
  //     }
  //
  //     onChangeItem();
  //     dispatch(
  //       actionsNotes.filtersSet({
  //         view: NOTES_VIEW.TIMELINE,
  //         filters: { dates },
  //       }),
  //     );
  //   },
  //   [onChangeItem, defaultValues, dispatch],
  // );

  return (
    <Grid container columnSpacing={2} rowSpacing={1} alignItems={'flex-end'}>
      <Grid item xs={'auto'}>
        <Controller
          name={'search'}
          control={control}
          render={(renderProps) => (
            <AppInputSearch
              {...getInputProps(renderProps)}
              label={' '}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: 200 }}
            />
          )}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          name={'dates'}
          control={control}
          render={(renderProps) => (
            <DateRangeStaticPicker
              label={' '}
              {...renderProps.field}
              onApply={onChangeItem}
              disableClearable
              NextButtonProps={{
                disabled: isLoading,
              }}
              PrevButtonProps={{
                disabled: isLoading,
              }}
              strategyButton={
                defaultValues.dateRangeType === DATE_RANGE_TYPE.WEEK
                  ? strategyButtonWeekly
                  : strategyButtonMonthly
              }
              CalendarProps={{
                pickerStrategy:
                  defaultValues.dateRangeType === DATE_RANGE_TYPE.WEEK
                    ? strategyCalendarPickerWeekly
                    : strategyCalendarPickerMonthly,
              }}
            />
          )}
        />
      </Grid>
      {/*<Grid item xs={'auto'}>*/}
      {/*  <Controller*/}
      {/*    name={'dateRangeType'}*/}
      {/*    control={control}*/}
      {/*    render={(renderProps) => (*/}
      {/*      <RadioButtons*/}
      {/*        value={renderProps.field.value}*/}
      {/*        onChange={composeFunctions(renderProps.field.onChange, handleChangeDateRangeType)}*/}
      {/*        options={dateRangeTypeOptions}*/}
      {/*        includeAll={false}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  />*/}
      {/*</Grid>*/}
    </Grid>
  );
};
