import { DateValue } from 'utils/dates';
import { Defined, FixServerObject, PatchPartial } from 'utils/types';
import * as yup from 'yup';
import { BaseImage, schemaBaseImage } from '../@shared/images';
import { ITicketActionItemStatus } from '../ticket-action-item-statuses';
import { IUserCrmProfile } from '../user-crm-profiles';

const API = 'TicketActionItems';

export const API_TICKET_ACTION_ITEMS = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<ITicketActionItem, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<ITicketActionItem, 'id'>) => `${API}/Delete/${data.id}`,
};

export interface ITicketActionItem
  extends FixServerObject<
    Components.Schemas.TicketActionItem,
    | 'id'
    | 'title'
    | 'ticketID'
    | 'done'
    | 'review'
    | 'priorityID'
    | 'reporterUserCrmProfileID'
    | 'assigneeUserCrmProfileID'
    | 'startDateAndTime'
    | 'endDateAndTime'
  > {}

export const schemaTicketActionItem = yup.object({
  id: yup.string().notRequired().default(undefined),
  title: yup
    .string()
    .required('rule-required')
    .min(2, 'rule-min-length')
    .max(1000, 'rule-max-length')
    .default(''),
  ticketID: yup.string().required('rule-required').default(''),
  reporterUserCrmProfileID: yup.string().required('rule-required').default(''),
  assigneeUserCrmProfileID: yup.string().required('rule-required').default(''),
  itemKey: yup.string().notRequired().nullable(),

  done: yup
    .boolean<boolean, { durationCount: number; mustTrackTime: boolean }>()
    .test('done', 'rule-must-track-time', (value, context) => {
      if (!value) return true;
      return !(
        context.options.context?.mustTrackTime === true &&
        context.options.context?.durationCount === 0
      );
    })
    .default(false),
  review: yup.boolean().default(false),

  priorityID: yup.string().required('rule-required').default(''),
  ticketActionItemStatusID: yup.string().when('done', {
    is: false,
    then: (schema) => schema.nullable().required('rule-required').default(''),
    otherwise: (schema) => schema.nullable().default(''),
  }),

  activityReportDescription: yup.string().nullable().default(''),
  entryDate: yup
    .string()
    .nullable()
    .notRequired()
    .default(() => new Date().toISOString()),

  description: yup.string().nullable().notRequired().default(''),

  startDateAndTime: yup.string().required('rule-required').default(''),
  endDateAndTime: yup.string().required('rule-required').default(''),

  images: yup.array().of(schemaBaseImage).max(5, 'rule-max'),
});

export interface ITicketActionItemPost extends Omit<ITicketActionItem, 'id'> {
  images?: BaseImage[];
}

export interface ITicketActionItemPatch extends PatchPartial<ITicketActionItem, 'id'> {
  images?: BaseImage[];
}

export interface ITicketActionItemDelete extends PatchPartial<ITicketActionItem, 'id'> {}
export interface ITicketActionItemPin extends PatchPartial<ITicketActionItem, 'id' | 'ticketID'> {}

export interface TrackingItem {
  duration: string;
  date: string;
  isAttached?: boolean;
}

export interface ITicketActionItemExtended extends ITicketActionItem {
  tracking: TrackingItem[];
}

export interface ITicketActionItemWithMeta extends ITicketActionItem {
  images: BaseImage[];
  _durationCount: number;
}

export interface ITicketActionPreview
  extends FixServerObject<
    Components.Schemas.TicketActionItem,
    'id' | 'description' | 'startDateAndTime' | 'endDateAndTime' | 'itemKey'
  > {
  priorityLabel: Components.Schemas.Priority['labelKey'];
  assignee: Components.Schemas.UserCrmProfile['fullName'];
  reporter: Components.Schemas.UserCrmProfile['fullName'];
  status: Components.Schemas.TicketStatus['labelKey'];
}

export interface GetTicketActionItemsForAutocompleteInput {
  search: string;
  assigneeUserCrmProfileID: string;
  projectID: string;
  date: DateValue;
}
export interface TicketActionItemsAutocomplete
  extends Pick<ITicketActionItem, 'id' | 'title' | 'done' | 'ticketID'> {
  status: Pick<ITicketActionItemStatus, 'labelKey' | 'color'>;
}

export interface TicketActionItemInfoForNotificationCreated
  extends Pick<ITicketActionItem, 'id' | 'title'> {
  reporter: Pick<Defined<IUserCrmProfile>, 'fullName' | 'appIdentityUserID'>;
  assignee: Pick<Defined<IUserCrmProfile>, 'fullName' | 'appIdentityUserID'>;
}

export interface TicketActionItemAutocomplete {
  id: string;
  title: string;
  status: { labelKey: string; color: string };
  pinned: boolean;
}
export interface TicketActionItemAutocompleteInput {
  search: string;
  ticketID: string;
  isPinned: boolean;
}
