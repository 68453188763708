import clsx from 'clsx';
import { IconButtonAdd } from 'components/buttons/icon-button-add';
import { useDataGridForm } from 'components/data-grid';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { format, startOfDay } from 'date-fns';
import { useAppSelector } from 'hooks';
import { QUERY_NAME } from 'pages/notes/helpers';
import React, { memo, useMemo } from 'react';
import { DATE_RANGE_TYPE, selectTimeLineFilters } from 'store/notes';
import { useMarks } from '../marks-provider';
import style from './index.module.scss';

interface Props {
  children?: React.ReactNode;
}

export const Wrapper = memo<Props>(({ children }) => {
  const { dateRangeType } = useAppSelector(selectTimeLineFilters);
  const { dates: Dates } = useMarks();
  const dates = useMemo(() => {
    const format_ = dateRangeType === DATE_RANGE_TYPE.WEEK ? 'E d MMM' : 'EEEEE d';
    const todayTimestamp = startOfDay(new Date()).getTime();
    return Dates.map((value) => {
      const [weekDay, day, month] = format(value, format_).split(' ');
      return {
        id: day,
        weekDay,
        day,
        month,
        today: todayTimestamp === value,
        future: value >= todayTimestamp,
      };
    });
  }, [Dates, dateRangeType]);

  const { onNew } = useDataGridForm({ name: QUERY_NAME });

  // render
  return (
    <NativeCustomScroll mode={'always'} className={style.wrapper}>
      <div className={clsx(style.header, dateRangeType === DATE_RANGE_TYPE.MONTH && style.monthly)}>
        {dates.map((value) => (
          <div key={value.id} className={clsx(style.column, value.today && style.today)}>
            <div className={style.day}>
              <div>
                {value.weekDay} <strong>{value.day}</strong> <span>{value.month}</span>
              </div>
              {value.future && (
                <IconButtonAdd onClick={onNew} size={'small'} className={style.add} />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={style.content}>
        <div className={style.grid}>
          {dates.map((date) => (
            <div className={clsx(style.line, date.today && style.today)} key={date.id} />
          ))}
        </div>
        <div className={style.inner}>{children}</div>
      </div>
    </NativeCustomScroll>
  );
});
