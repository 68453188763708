import { useAppSelector } from 'hooks';
import { memo } from 'react';
import { selectDataFullFilteredForTimeLine } from 'store/notes';
import { MarksProvider } from './components/marks-provider';
import { NoteRow } from './components/note-row';
import { Wrapper } from './components/wrapper';

type NotesDataFullType = ReturnType<typeof selectDataFullFilteredForTimeLine>;
export type NoteType = NotesDataFullType[number];

export const ViewTimeLine = memo(() => {
  // state
  const notes = useAppSelector(selectDataFullFilteredForTimeLine);
  // render
  return (
    <MarksProvider>
      <Wrapper>
        {notes.map((note) => (
          <NoteRow key={note.id} data={note} />
        ))}
      </Wrapper>
    </MarksProvider>
  );
});

export * from './components/filters';
