import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ButtonBase, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useAppSelector, useTranslate } from 'hooks';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actionsNotes, selectCalendarFilters, selectCalendarSelectedDate } from 'store/notes';
import { CalendarGridBadge } from '../calendar-grid-badge';
import { ICalendarDay } from '../data-provider';
import style from './index.module.scss';

interface Props {
  data: ICalendarDay;
}

export const Day = memo<Props>(({ data }) => {
  const { tp } = useTranslate();
  const dispatch = useDispatch();

  const { dates } = useAppSelector(selectCalendarFilters);
  const selectedDate = useAppSelector(selectCalendarSelectedDate);

  const [day /*, month*/] = useMemo(() => format(data.date, 'd MMM').split(' '), [data.date]);
  const { isToday, isOutOfMonth, isPast, isSelected } = useMemo(() => {
    const now = new Date();
    return {
      isOutOfMonth: data.date.getMonth() !== dates[0].getMonth(),
      isPast: format(data.date, 'yyyy-MM-dd') < format(now, 'yyyy-MM-dd'),
      isToday: format(data.date, 'yyyy-MM-dd') === format(now, 'yyyy-MM-dd'),
      isSelected: format(data.date, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd'),
    };
  }, [data.date, dates, selectedDate]);

  const notes = useMemo(
    () =>
      data.notes.reduce((acc, note) => {
        const key = note?._customer?.title || 'without-customer';
        if (!acc[key]) acc[key] = [];
        acc[key].push(note);
        return acc;
      }, {} as Record<string, typeof data.notes>),
    [data],
  );

  const handleClick = useCallback(() => {
    dispatch(actionsNotes.setSelectedDate(data.date));
  }, [data.date, dispatch]);

  const handleNewNote = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      dispatch(actionsNotes.setAddNewNote({ entryDate: data.date }));
    },
    [dispatch, data],
  );

  return (
    <ButtonBase
      className={clsx(
        style.root,
        isToday && style.today,
        isOutOfMonth && style.outside,
        isPast && style.past,
        isSelected && style.selected,
        !Object.keys(notes).length && style.empty,
      )}
      onClick={handleClick}
    >
      <div className={style.dayTitle}>
        {isSelected && <CheckCircleIcon className={style.checkIcon} />}
        <div className={style.day}>{day}</div>
      </div>
      <Tooltip title={tp('add-new-note')} placement={'top'}>
        <div className={style.addNote} onClick={handleNewNote}>
          <AddIcon className={style.icon} />
        </div>
      </Tooltip>
      <div className={style.notes}>
        {Object.entries(notes)
          .reverse()
          .map(([key, value]) => (
            <CalendarGridBadge
              data={value}
              key={key}
              isPast={isPast}
              isOutOfMonth={isOutOfMonth}
              date={data.date}
            />
          ))}
      </div>
    </ButtonBase>
  );
});
