import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

export const useElementSize = <T extends HTMLElement = HTMLElement>() => {
  const ref = useRef<T>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const refObserver = useRef(
    new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        setSize((prev) => {
          const { width, height } = entry.target.getBoundingClientRect();
          let newState = { width, height };

          return isEqual(prev, newState) ? prev : newState;
        });
      }
    }),
  );

  useEffect(() => {
    const el = ref.current;
    const observer = refObserver.current;
    if (el) observer.observe(el);

    return () => {
      if (el) observer.unobserve(el);
    };
  }, []);

  return [ref, size] as const;
};
