import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { Item, MenuButton } from 'components/menu-button';
import { useAppDispatch, useTranslate } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import { memo, useCallback, useMemo } from 'react';
import { actionsNotes } from 'store/notes';
import { QUERY_NAME } from '../../helpers';
import { NoteType } from '../view-time-line';
import style from './index.module.scss';

interface Prop {
  data: NoteType;
  className?: string;
}

export const NoteDropMenu = memo<Prop>(({ data, className }) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const { onChange } = useValueQuery({ name: QUERY_NAME });
  const onEdit = useCallback(() => onChange(data.id), [data.id, onChange]);

  // archive
  const ArchiveIcon = !data.isActive ? RestoreIcon : ArchiveOutlinedIcon;
  const onArchive = useCallback(() => {
    dispatch(actionsNotes.setArchive(data));
  }, [dispatch, data]);

  // ticket
  const AttachToTicketIcon = data.itemKey ? RemoveRedEyeIcon : AssignmentIcon;
  const onAttachToTicket = useCallback(() => {
    dispatch(actionsNotes.setAttachToTicket(data));
  }, [dispatch, data]);

  const menuItems = useMemo<Item[]>(
    () => [
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <EditIcon fontSize={'inherit'} sx={{ marginRight: '0.6rem', fontSize: '1.8rem' }} />
            <Typography>{'edit'}</Typography>
          </Stack>
        ),
        onClick: onEdit,
      },
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <ArchiveIcon
              fontSize={'inherit'}
              sx={{ marginRight: '0.6rem', fontSize: '1.8rem' }}
              color={'silver'}
            />
            <Typography color={'error'}>{tp(!data.isActive ? 'restore' : 'archive')}</Typography>
          </Stack>
        ),
        onClick: onArchive,
      },
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <AttachToTicketIcon
              fontSize={'inherit'}
              sx={{ marginRight: '0.6rem', fontSize: '1.8rem' }}
            />
            <Typography>
              {tp(data.itemKey ? 'view-ticket-action-item' : 'create-ticket-action-item')}
            </Typography>
          </Stack>
        ),
        onClick: onAttachToTicket,
      },
    ],
    [onEdit, data, ArchiveIcon, onArchive, onAttachToTicket, AttachToTicketIcon, tp],
  );

  return (
    <MenuButton items={menuItems} isPreventDefault={false}>
      <IconButton className={clsx(style.btnEdit, className)}>
        <MoreVertIcon />
      </IconButton>
    </MenuButton>
  );
});
