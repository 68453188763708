import { memo } from 'react';
import { CalendarNoteType } from '../data-provider';
import { DueDateNotesGroup } from '../due-date-notes-group';

interface Props {
  data: Record<string, CalendarNoteType[]>;
  count: number;
}

export const DueDateNotes = memo<Props>(({ data }) => {
  return (
    <>
      {Object.entries(data).map(([key, value]) => (
        <DueDateNotesGroup key={key} title={key} data={value} />
      ))}
    </>
  );
});
