import clsx from 'clsx';
import React, { useMemo } from 'react';
import style from './index.module.scss';

interface Props {
  value: string | null;
}

export const TemplateHtmlClamp1: React.FC<Props> = ({ value }) => {
  const textContent = useMemo(() => {
    return new DOMParser().parseFromString(value || '', 'text/html').body.textContent;
  }, [value]);
  return <div className={clsx(style.root)}>{textContent}</div>;
};

export const TemplateHtmlClamp2: React.FC<Props> = ({ value }) => {
  return <div className={style.wrap} dangerouslySetInnerHTML={{ __html: value || '' }} />;
};

export const TemplateHtmlClamp = TemplateHtmlClamp2;
