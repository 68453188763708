import { Box, BoxProps, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { Progress } from 'pages/notes/components/view-grid/components/progress';
import React from 'react';
import { Unset } from 'utils/types';
import style from './index.module.scss';

interface Classes {
  root: string;
  corner: string;
}

interface Props extends Omit<BoxProps, 'title'> {
  isOpen?: boolean;
  isExpired?: boolean;
  color: string;
  progress: number;
  title?: Unset | string;
  slotControls?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  classes?: Partial<Classes>;
}

export const CardLayout: React.FC<Props> = ({
  isOpen,
  isExpired,
  color,
  progress,
  title,
  slotControls,
  className,
  children,
  classes,
  ...rest
}) => {
  return (
    <Box
      className={clsx(className, style.root, isExpired && style.expired, isOpen && style.hover)}
      {...rest}
    >
      <div className={style.layout}>
        <div className={style.corner} />
        <Progress color={color} value={progress} />
        <Box
          className={style.bg}
          sx={{
            background: `linear-gradient(${color}, #fff)`,
          }}
        />
      </div>
      <Stack direction={'row'}>
        <Box alignSelf={'center'} flexGrow={1}>
          <Typography
            variant={'caption'}
            component={'div'}
            flexGrow={1}
            sx={{
              opacity: 0.5,
              mb: 0.4,
            }}
          >
            {title}
          </Typography>
        </Box>
        <div className={style.controls}>{slotControls}</div>
      </Stack>
      {children}
    </Box>
  );
};
