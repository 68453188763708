import { Fade, Paper, Popper, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import { DescriptionContent } from 'pages/notes/components/description-content';
import React, { memo } from 'react';
import { IBoardNote } from 'services/notes';
import { CalendarNoteType } from '../data-provider';
import style from './index.module.scss';

interface Props {
  data: CalendarNoteType | IBoardNote;
  children: React.ReactNode;
}

export const NoteDescriptionTooltip = memo<Props>(({ data, children }) => {
  const { tp } = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  // render
  return data?.description ? (
    <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={handleMouseLeave}>
      {children}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="left"
        style={{ zIndex: 1300 }}
        transition
        className={style.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={style.paper}>
              <Typography
                variant={'caption'}
                component={'div'}
                fontWeight={600}
                color={'text.secondary'}
                mb={1}
              >
                {tp('description')}
              </Typography>
              <DescriptionContent html={data?.description} />
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  ) : (
    <>{children}</>
  );
});
