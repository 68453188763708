import { memo } from 'react';
import { CalendarDataProvider, CalendarGrid } from './components';
import style from './components/index.module.scss';
import { RightSide } from './components/right-side';

export const ViewCalendar = memo(() => {
  return (
    <CalendarDataProvider>
      <div className={style.root}>
        <CalendarGrid />
        <RightSide />
      </div>
    </CalendarDataProvider>
  );
});

export * from './components/filters';
