import React, { useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';
import { AppInputSearch } from 'components/app-input-search';
import { RadioButtons } from 'components/radio-buttons';
import {
  useAppDispatch,
  useAppSelector,
  useFilterForm,
  useFilterInputProps,
  useTranslate,
} from 'hooks';
import { Controller } from 'react-hook-form';
import {
  actionsNotes,
  NOTES_DUE_DATE,
  NOTES_VIEW,
  selectGridFilters,
  selectNotesStatuses,
} from 'store/notes';
import { composeFunctions } from 'utils/other';
import { enumToArray } from 'utils/types';

interface Props {}

export const FiltersGrid: React.FC<Props> = () => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectNotesStatuses);
  const defaultValues = useAppSelector(selectGridFilters);
  const onUpdate = useCallback(
    (formValue: typeof defaultValues) => {
      dispatch(actionsNotes.filtersSet({ view: NOTES_VIEW.GRID, filters: formValue }));
    },
    [dispatch],
  );

  const { control, onChangeItem } = useFilterForm({ defaultValues, onUpdate });

  const getInputProps = useFilterInputProps({ onChangeItem, disabled: isLoading });

  const dueDateOptions = useMemo(
    () => enumToArray(NOTES_DUE_DATE).map(({ id }) => ({ id, title: t(id) }), []),
    [t],
  );

  return (
    <Grid container columnSpacing={2} rowSpacing={1} alignItems={'flex-end'}>
      <Grid item xs={'auto'}>
        <Controller
          name={'search'}
          control={control}
          render={(renderProps) => (
            <AppInputSearch
              {...getInputProps(renderProps)}
              label={' '}
              InputLabelProps={{ shrink: true }}
              sx={{
                minWidth: 314,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          name={'dates'}
          control={control}
          render={(renderProps) => (
            <RadioButtons
              value={renderProps.field.value}
              onChange={composeFunctions(renderProps.field.onChange, onChangeItem)}
              options={dueDateOptions}
              includeAll={false}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
