import { DraggableItemAutoScroll } from 'hooks/use-drageble-auto-scroll';

export const QUERY_NAME = 'note';

export enum DRAG_TYPES {
  CARD = 'notes',
}

export interface DragItem extends DraggableItemAutoScroll {
  id: string;
  index: number;
}

export interface DropResult {
  index: number;
}
