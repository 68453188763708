import { Box, ButtonBase, Collapse } from '@mui/material';
import clsx from 'clsx';
import { useOpen } from 'hooks';
import React, { memo, useMemo } from 'react';
import variables from 'styles/config.scss';
import { CustomerGroupNote } from '../customer-group-note';
import { CalendarNoteType } from '../data-provider';
import style from './index.module.scss';

interface Props {
  data: CalendarNoteType[];
  title: string;
  open?: boolean;
}

export const CustomerGroup = memo<Props>(({ data, title, open }) => {
  const { isOpen, onToggle } = useOpen(open || false);

  const rootStyle = useMemo(() => {
    const borderColor = data[0]?._customer?.color ? data[0]._customer.color : variables.colorBlack;
    // const luminance = applyOpacityEffectHex(borderColor, 0.11);
    return {
      '--border': borderColor,
      // '--background': luminance,
      // '--color': getContrastColor(luminance),
    } as React.CSSProperties;
  }, [data]);

  const notes = useMemo(
    () => data.map((note) => <CustomerGroupNote key={note.id} data={note} />),
    [data],
  );

  return (
    <div style={rootStyle} className={style.root}>
      <ButtonBase
        className={clsx(style.btnCollapse, isOpen ? style.open : style.close)}
        onClick={onToggle}
      >
        <div className={style.title}>
          <span>{title}</span>
        </div>
        <div className={style.badge}>{data.length}</div>
      </ButtonBase>
      <Collapse in={!isOpen} timeout="auto" unmountOnExit>
        <Box mt={0.3} mb={-0.1}>
          {notes}
        </Box>
      </Collapse>
    </div>
  );
});
