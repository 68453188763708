import { Fade, Paper, Popper } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import React, { memo, useMemo } from 'react';
import variables from 'styles/config.scss';
import { CalendarNoteType } from '../data-provider';
import style from './index.module.scss';
import { Note } from './note';

interface Props {
  data: CalendarNoteType[];
  isPast: boolean;
  isOutOfMonth: boolean;
  date: Date;
}

export type CalendarNoteWithDueToday = CalendarNoteType & { isDueToday: boolean };

export const CalendarGridBadge = memo<Props>(({ data, isPast, isOutOfMonth, date }) => {
  const { tp } = useTranslate();

  const customer = useMemo(() => data[0]?._customer?.title || tp('without-customer'), [data, tp]);

  const rootStyle = useMemo(() => {
    const borderColor = data[0]?._customer?.color ? data[0]._customer.color : variables.colorBlack;
    // const luminance = applyOpacityEffectHex(borderColor, 0.06);
    return {
      '--border': borderColor,
      // '--background': luminance,
      // '--color': getContrastColor(luminance),
    } as React.CSSProperties;
  }, [data]);

  const { isDueToday, notes } = useMemo(() => {
    const date_ = date.toISOString().slice(0, 10);
    return data.reduce<{
      isDueToday: boolean;
      notes: CalendarNoteWithDueToday[];
    }>(
      (acc, value) => {
        const isDueToday = new Date(value.dueDate).toISOString().slice(0, 10) === date_;
        acc.isDueToday = acc.isDueToday || isDueToday;
        acc.notes.push({ ...value, isDueToday });
        return acc;
      },
      { isDueToday: false, notes: [] },
    );
  }, [data, date]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  // render
  return (
    <>
      <div
        style={rootStyle}
        className={clsx(
          style.root,
          isDueToday && style.dueToday,
          isOutOfMonth && style.outOfMonth,
          isPast && style.past,
        )}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <span className={style.count}>{data.length}</span>
      </div>
      <Popper
        // open={Boolean(anchorEl)}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right-end"
        style={{ zIndex: 1300 }}
        transition
        className={style.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={style.paper} style={rootStyle}>
              <div className={style.paperTitle}>{customer}</div>
              <div className={style.paperContent}>
                {notes.map((note) => (
                  <Note key={note.id} data={note} />
                ))}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
});
