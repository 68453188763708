import { useElementSize } from 'hooks/use-element-size';
import React, { useMemo } from 'react';
import style from './index.module.scss';

type Size = { height: number };
type Item = { _size: Size };

const findMinIndex = (arr: { height: number }[]) => {
  let min = arr[0].height;
  let minIndex = 0;

  for (let i = 1; i < arr.length; i++) {
    let val = arr[i].height;
    if (val < min) {
      min = val;
      minIndex = i;
    }
  }

  return minIndex;
};

type ListItem<T> = { row: T; x: number; y: number; width: number };

interface Props<T> {
  rows: T[];
  columns: number;
  renderRow: (item: ListItem<T>, index: number) => React.ReactNode;
}

export const GridArea = <T extends Item>(props: Props<T>) => {
  const [ref, { width: _width }] = useElementSize<HTMLDivElement>();
  const { columns, rows, renderRow } = props;
  const { height, list } = useMemo(() => {
    const width = _width / columns;

    let list: Array<ListItem<T>> = [];
    let cols = Array.from({ length: columns })
      .fill(null)
      .map(() => ({ height: 0 }));

    for (let i = 0; i < rows.length; i++) {
      let minIndex = findMinIndex(cols);
      const row = rows[i];
      let col = cols[minIndex];

      list.push({ row, x: minIndex * width, y: col.height, width });

      col.height += row._size.height;
    }

    let height = Math.max(...cols.map((col) => col.height));

    return { list, height };
  }, [rows, columns, _width]);

  return (
    <div ref={ref} className={style.root} style={{ height: height, position: 'relative' }}>
      {list.map(renderRow)}
    </div>
  );
};
