import { Theme, useMediaQuery } from '@mui/material';
import { NativeCustomScroll } from 'components/native-custom-scroll';
import { motion } from 'framer-motion';
import { useAppSelector } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { selectGridRows } from 'store/notes';
import { selectNotesLayoutExpanded } from 'store/notes-layout';
import { ArrayType } from 'utils/types';
import style from '../../index.module.scss';
import { Card } from './components/card';
import { DraggableWrapper } from './components/dragable-wrapper';
import { GridArea } from './components/grid-area';

export const ViewGrid = memo(() => {
  const isExpanded = useAppSelector(selectNotesLayoutExpanded);
  const rows = useAppSelector(selectGridRows);

  const renderRow = useCallback(
    (
      renderProps: { row: ArrayType<typeof rows>; x: number; y: number; width: number },
      index: number,
    ) => {
      const { row, x, y, width } = renderProps;
      return (
        <motion.div
          key={row.id}
          style={{ position: 'absolute', width: width }}
          animate={{ x, y }}
          className={style.cell}
        >
          <DraggableWrapper id={row.id} orderIndex={row._orderIndex} index={index}>
            <Card data={row} customer={row._customer} archiveDate={row._archiveDate} />
          </DraggableWrapper>
        </motion.div>
      );
    },
    [],
  );

  const isXXL = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xxl'));
  const isLG = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const isMD = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const columns = useMemo(() => {
    if (isXXL) return isExpanded ? 4 : 5;
    if (isLG) return isExpanded ? 3 : 4;
    if (isMD) return isExpanded ? 2 : 3;
    if (isSM) return isExpanded ? 1 : 2;
    return 1;
  }, [isXXL, isLG, isMD, isSM, isExpanded]);

  return (
    <NativeCustomScroll mode={'always'}>
      <GridArea rows={rows} columns={columns} renderRow={renderRow} />
    </NativeCustomScroll>
  );
});

export * from './components/filters';
