import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { ButtonCancel, ButtonCreate } from 'components/buttons';
import { DataGrid, DataGridColumn, TDataGridRenderCell } from 'components/data-grid';
import { DialogHeader } from 'components/dialog-header';
import { NativeScroll } from 'components/native-scroll';
import { useTranslate } from 'hooks';
import { NOTE_DEFAULT_COLOR } from 'layouts/notes/helpers';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Unset } from 'utils/types';
import * as yup from 'yup';
import { InferType } from 'yup';
import style from './index.module.scss';
import { GridModel, Item } from './models';
import { useGridConfig } from './use-grid-config';

const HeadCell: React.FC = () => null;

const schemaDay = yup
  .number()
  .typeError('rule-number')
  .nullable()
  .min(1, 'rule-min')
  .max(30, 'rule-max');

interface Props {
  isLoading: boolean;
  items: Item[];
  onClose: () => void;
  onSubmit: (rows: { id: string; days: Unset<number> }[]) => void;
}
export const DialogExpiredNotes: React.FC<Props> = ({ isLoading, items, onClose, onSubmit }) => {
  const { tp } = useTranslate();
  const [rows] = useState(() => {
    return items.map((row) => ({ ...row, days: 0 }));
  });

  const { schema, defaultValues } = useMemo(() => {
    const { entries, defaultValues } = rows.reduce(
      (acc, row) => {
        acc.entries.push([row.id, schemaDay]);
        acc.defaultValues[row.id] = null;
        return acc;
      },

      {
        entries: [] as [string, typeof schemaDay][],
        defaultValues: {} as Record<string, InferType<typeof schemaDay>>,
      },
    );
    const schema = yup.object(Object.fromEntries(entries));

    return { schema: schema, defaultValues };
  }, [rows]);

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = formMethods;

  const { gridColumns } = useGridConfig();
  const performSubmit = useCallback(
    (formData: Record<string, InferType<typeof schemaDay>>) => {
      const res = Object.entries(formData).map(([id, days]) => ({ id, days }));
      onSubmit(res);
    },
    [onSubmit],
  );

  const renderMarkerRow = useCallback<TDataGridRenderCell<GridModel>>(({ row }) => {
    const color = row.customer?.color || NOTE_DEFAULT_COLOR;
    return <div className={style.marker} style={{ backgroundColor: color }} />;
  }, []);

  return (
    <Dialog open={true} fullWidth maxWidth={'lg'} onClose={onClose}>
      <DialogHeader isLoading={isLoading} title={tp('notes-archive-manager')} onClose={onClose} />
      <DialogContent sx={{ overflow: 'hidden', display: 'grid' }}>
        <NativeScroll>
          <FormProvider {...formMethods}>
            <DataGrid rows={rows} rowKey={'id'}>
              <DataGridColumn
                sticky={'left'}
                title={null}
                field={'_marker'}
                HeadCell={HeadCell}
                HeadCellProps={{ p: 0 }}
                CellProps={{ p: 0 }}
                children={renderMarkerRow}
              />
              {gridColumns.map((column, i) => {
                return <DataGridColumn key={i} {...column} />;
              })}
            </DataGrid>
          </FormProvider>
        </NativeScroll>
      </DialogContent>
      <DialogActions>
        <ButtonCancel onClick={onClose} />
        <ButtonCreate disabled={isLoading} onClick={handleSubmit(performSubmit)}>
          {tp('save')}
        </ButtonCreate>
      </DialogActions>
    </Dialog>
  );
};
