import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { Avatar, Box, MenuItem, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { MenuButton } from 'components/menu-button';
import { APP_ROUTES } from 'configs';
import {
  useAppDispatch,
  useCurrentUser,
  useMountedRef,
  useOpen,
  usePermissions,
  useTranslate,
} from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionAccountLogout } from 'store/auth';
import VARS from 'styles/config.scss';
import { getShortFullName } from 'utils/app-helpers';
import style from './index.module.scss';

const MenuProps = {
  transformOrigin: { horizontal: 'right', vertical: 'top' },
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  variant: 'menu',
} as const;

interface Props {}

export const HeaderProfile: React.FC<Props> = () => {
  const { tp } = useTranslate();
  const {
    name,
    userPhoto,
    userCrmProfilePermission: { title },
  } = useCurrentUser();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useMountedRef();
  const dispatch = useAppDispatch();

  const onLogout = useCallback(async () => {
    setIsLoading(true);

    await dispatch(actionAccountLogout());

    if (!mountedRef.current) return;
    setIsLoading(false);
  }, [mountedRef, dispatch]);

  const menuItems = useMemo(() => {
    return [
      { title: tp('account-settings'), onClick: () => navigate(APP_ROUTES.PROFILE.path) },
      ...(permissions.isAdmin
        ? [
            { title: tp('settings'), onClick: () => navigate(APP_ROUTES.LABELS.path) },
            { title: tp('expenses'), onClick: () => navigate(APP_ROUTES.EXPENSES.path) },
          ]
        : []),
      { title: tp('log-out'), disabled: isLoading, onClick: onLogout },
    ];
  }, [tp, navigate, isLoading, onLogout, permissions.isAdmin]);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { isOpen, onOpen, onClose } = useOpen();

  return (
    <MenuButton
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      items={menuItems}
      MenuProps={MenuProps}
    >
      <MenuItem component={'button'} className={style.root}>
        {!isMobile && (
          <Box>
            <Typography
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              component={'div'}
              variant={'body2'}
              title={name}
            >
              {getShortFullName(name)}
            </Typography>
            <Typography
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              component={'div'}
              variant={'body2'}
              className={style.title}
            >
              {title}
            </Typography>
          </Box>
        )}

        <Box className={style.avatar}>
          <Avatar sx={{ width: 34, height: 34 }} src={userPhoto || ''} />
        </Box>

        <ArrowDropDownTwoToneIcon
          className={clsx(style.icon, isOpen && style.iconOpen)}
          color={'inherit'}
          sx={{ color: VARS.colorGrey }}
        />
      </MenuItem>
    </MenuButton>
  );
};
