import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { useTranslate } from 'hooks';
import React from 'react';

interface Props extends ButtonProps {}

export const ButtonArchive: React.FC<Props> = ({ title, children, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Tooltip title={title || tp('archive-tooltip')} arrow>
      <Button color={'error'} style={{minWidth: 0}} {...rest}><ArchiveOutlinedIcon /></Button>
    </Tooltip>
  );
};
