import { Typography } from '@mui/material';
import { factoryCreateConfigColumn } from 'components/data-grid-config';
import { TemplateHtmlClamp } from 'components/data-grid-templates/template-html-mode';
import { useTranslate } from 'hooks';
import { useMemo } from 'react';
import { ColumnDays } from './components/column-days';
import { GridModel } from './models';

const createColumn = factoryCreateConfigColumn<GridModel>({
  gridProps: { sortable: false },
});

export const useGridConfig = () => {
  const { tp } = useTranslate();
  const configColumns = useMemo(() => {
    return [
      createColumn({
        field: 'id',
        title: tp('customer'),
        gridProps: {
          children: ({ row }) => row.customer?.title,
        },
      }),
      createColumn({
        field: 'title',
        title: tp('title-or-description'),
        gridProps: {
          width: '100%',
          children: ({ row }) =>
            row.title ? (
              <Typography fontWeight={'bold'}>{row.title}</Typography>
            ) : (
              <TemplateHtmlClamp value={row.description || ''} />
            ),
        },
      }),
      createColumn({
        field: 'days',
        title: tp('extend-expiration-date'),
        gridProps: {
          children: ({ row }) => <ColumnDays row={row} />,
        },
      }),
    ];
  }, [tp]);

  const gridColumns = useMemo(
    () => configColumns.map((col) => ({ ...col.gridProps })),
    [configColumns],
  );

  return { gridColumns };
};
