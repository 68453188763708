import ErrorIcon from '@mui/icons-material/Error';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { Stack, Tooltip } from '@mui/material';
import { TemplateDate } from 'components/data-grid-templates';
import { formatDistance, startOfDay } from 'date-fns';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { convertToDate, DateValue, formatDate, minDate } from 'utils/dates';

const makePayload = (date: Date) => {
  let now = minDate(new Date(), date);

  return { distance: formatDistance(now, date), date: formatDate(date) };
};

interface Props {
  entryDate: DateValue;
  dueDate: DateValue;
  archiveDate: DateValue;
}

export const DateRange: React.FC<Props> = ({ entryDate, dueDate }) => {
  const { tp } = useTranslate();

  const { entryDateIn, dueDateIn, isExpired, isExpiredToday } = useMemo(() => {
    const today = startOfDay(new Date()).getTime();

    const dueDateIn = convertToDate(dueDate);
    const entryDateIn = convertToDate(entryDate);
    // const archiveDateIn = convertToDate(archiveDate);

    return {
      dueDateIn,
      entryDateIn,
      // archiveDateIn,
      isExpiredToday: today === startOfDay(dueDateIn).getTime(),
      isExpired: today > startOfDay(dueDateIn).getTime(),
    };
  }, [dueDate, entryDate]);

  const tooltipEntryDate = useMemo(
    () => tp('note-expired-entry-date', makePayload(convertToDate(entryDateIn))),
    [entryDateIn, tp],
  );

  const tooltipDueDate = useMemo(() => {
    const key = isExpiredToday
      ? 'due-date-is-today'
      : isExpired
      ? 'past-due-date'
      : 'note-expired-due-date';
    return tp(key, makePayload(dueDateIn));
  }, [isExpiredToday, isExpired, dueDateIn, tp]);

  return (
    <Stack mb={0.7} direction={'row'} alignItems={'center'} spacing={0.5}>
      <Stack direction={'row'} alignItems={'center'} spacing={0.3}>
        <Tooltip title={tooltipEntryDate}>
          <InsertInvitationIcon fontSize={'inherit'} sx={{ fontSize: '1.6rem' }} color={'action'} />
        </Tooltip>
        <TemplateDate
          variant={'caption'}
          fontWeight={600}
          sx={{
            opacity: 0.5,
          }}
          value={entryDate}
        />
      </Stack>
      <div>-</div>
      <Stack direction={'row'} alignItems={'center'} spacing={0.3}>
        <Tooltip title={tooltipDueDate}>
          {isExpired || isExpiredToday ? (
            <ErrorIcon
              fontSize={'inherit'}
              sx={{ fontSize: '1.6rem' }}
              color={isExpired ? 'error' : 'warning'}
            />
          ) : (
            <InsertInvitationIcon
              fontSize={'inherit'}
              sx={{ fontSize: '1.8rem' }}
              color={'action'}
            />
          )}
        </Tooltip>
        <TemplateDate
          variant={'caption'}
          fontWeight={600}
          sx={{
            opacity: 0.5,
          }}
          value={dueDate}
        />
      </Stack>
    </Stack>
  );
};
