import { createSelector } from '@reduxjs/toolkit';
import { groupBy, keyBy } from 'lodash-es';
import { selectSourceTicketActionItemStatusesAll } from 'services/ticket-action-item-statuses';
import { calcTrackingData } from 'services/ticket-action-items';
import { selectSourceCrmUsersAllMap } from 'services/user-crm-profiles';
import {
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFilteredMap,
  selectTicketsDataFullMap,
} from '../check-done-tickets';
import { AppState } from '../index';

const selectState = (state: AppState) => state.checkDoneNotCompleted;

export const selectCheckDoneNotCompletedStatus = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);

const selectCheckDoneNotCompletedOrders = createSelector(selectState, ({ orders }) => {
  return orders;
});

export const selectCheckDoneNotCompletedFilters = createSelector(selectState, ({ filters }) => {
  return filters;
});

export const selectCheckDoneNotCompletedData = createSelector(selectState, ({ data }) => {
  return data;
});
export const selectCheckDoneNotCompletedLastOrderedID = createSelector(
  selectState,
  ({ lastOrderedID }) => {
    return lastOrderedID;
  },
);
const selectCheckDoneNotCompletedDataFull = createSelector(
  selectCheckDoneNotCompletedData,
  selectSourceCrmUsersAllMap,
  selectTicketsDataFullMap,
  (ticketItems, mapUsers, ticketsMap) => {
    return ticketItems.map((ticketItem) => {
      const { durationInMinutes, count } = calcTrackingData(ticketItem.tracking);
      return {
        ...ticketItem,
        _ticket: ticketsMap[ticketItem.ticketID],
        _reporter: mapUsers[ticketItem.reporterUserCrmProfileID],
        _assigner: mapUsers[ticketItem.assigneeUserCrmProfileID],
        _timeTrackingInMinutes: durationInMinutes,
        _timeTrackingCount: count,
      };
    });
  },
);

export const selectCheckDoneNotCompletedDataFullMap = createSelector(
  selectCheckDoneNotCompletedDataFull,
  (list) => {
    return keyBy(list, 'id');
  },
);

const selectCheckDoneNotCompletedDataFiltered = createSelector(
  selectCheckDoneNotCompletedDataFull,
  selectCheckDoneNotCompletedFilters,
  selectCheckDoneTicketsSelectedID,
  selectTicketsDataFilteredMap,
  (items, filters, selectedTicketID, mapTickets) => {
    const {
      search,
      reporterUserCrmProfileID,
      assigneeUserCrmProfileIDs,
      ticketActionPriorityID,
      ticketActionStatusID,
    } = filters;

    return items.filter((item) => {
      const res: boolean[] = [true];

      if (!mapTickets[item.ticketID]) {
        return false;
      }

      if (selectedTicketID) {
        if (item.ticketID !== selectedTicketID) return false;
      }

      if (search) {
        res.push(
          [
            item.title.includes(search),
            item._reporter?.fullName.includes(search),
            item._assigner?.fullName.includes(search),
            item.itemKey?.includes(search),
          ].some(Boolean),
        );
      }

      if (reporterUserCrmProfileID) {
        res.push(reporterUserCrmProfileID === item.reporterUserCrmProfileID);
      }

      if (assigneeUserCrmProfileIDs.length) {
        res.push(assigneeUserCrmProfileIDs.some((id) => id === item.assigneeUserCrmProfileID));
      }

      if (ticketActionPriorityID) {
        res.push(ticketActionPriorityID === item.priorityID);
      }

      if (ticketActionStatusID) {
        res.push(ticketActionStatusID === item.ticketActionItemStatusID);
      }

      return res.every(Boolean);
    });
  },
);

const selectSortedData = createSelector(
  selectCheckDoneNotCompletedDataFiltered,
  selectCheckDoneNotCompletedOrders,
  (list, orders) => {
    return list
      .map((item) => {
        return { ...item, _orderIndex: orders[item.id] || item.rowIndex || 0 };
      })
      .sort((a, b) => a._orderIndex - b._orderIndex);
  },
);
export const selectCheckDoneNotCompletedDataGroupByStatus = createSelector(
  selectSourceTicketActionItemStatusesAll,
  selectSortedData,
  (statuses, ticketActionItems) => {
    const groups = groupBy(ticketActionItems, 'ticketActionItemStatusID');

    return statuses.map((status, i) => {
      const ticketActionItems = groups[status.id] || [];

      return {
        ...status,
        _ticketActionItems: ticketActionItems,
      };
    });
  },
);
