import React, { useCallback } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Grid } from '@mui/material';
import { AppInputSearch } from 'components/app-input-search';
import {
  DateRangeStaticPicker,
  strategyButtonMonthly,
  strategyCalendarPickerMonthly,
} from 'components/date-picker-range';
import {
  useAppDispatch,
  useAppSelector,
  useFilterForm,
  useFilterInputProps,
  useTranslate,
} from 'hooks';
import { Controller } from 'react-hook-form';
import { actionsNotes, NOTES_VIEW, selectCalendarFilters, selectNotesStatuses } from 'store/notes';
import styles from './index.module.scss';

const MONTH_INPUT_FORMAT = 'MMMM yyyy';

interface Props {}

export const FiltersCalendar: React.FC<Props> = () => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(selectNotesStatuses);
  const defaultValues = useAppSelector(selectCalendarFilters);
  const onUpdate = useCallback(
    (formValue: typeof defaultValues) => {
      dispatch(actionsNotes.filtersSet({ view: NOTES_VIEW.CALENDAR, filters: formValue }));
    },
    [dispatch],
  );

  const { control, onChangeItem } = useFilterForm({ defaultValues, onUpdate });

  const getInputProps = useFilterInputProps({ onChangeItem, disabled: isLoading });

  return (
    <Grid container columnSpacing={2} rowSpacing={1} alignItems={'flex-end'}>
      <Grid item xs={'auto'}>
        <Controller
          name={'search'}
          control={control}
          render={(renderProps) => (
            <AppInputSearch
              {...getInputProps(renderProps)}
              label={' '}
              InputLabelProps={{ shrink: true }}
              sx={{ minWidth: '27rem' }}
            />
          )}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          name={'dates'}
          control={control}
          render={(renderProps) => (
            <DateRangeStaticPicker
              label={tp('current-month')}
              {...renderProps.field}
              onApply={onChangeItem}
              disableClearable
              PrevButtonProps={{
                disabled: isLoading,
                className: styles.calendarButton,
                children: <ChevronLeftIcon />,
              }}
              NextButtonProps={{
                disabled: isLoading,
                className: styles.calendarButton,
                children: <ChevronRightIcon />,
              }}
              strategyButton={strategyButtonMonthly}
              isMonthFormat
              inputFormat={MONTH_INPUT_FORMAT}
              CalendarProps={{
                pickerStrategy: strategyCalendarPickerMonthly,
                views: ['year', 'month'],
                openTo: 'month',
              }}
              InputProps={{
                classes: {
                  input: styles.input,
                },
                endAdornment: null,
              }}
              InputLabelProps={{
                className: styles.label,
              }}
              sx={{ minWidth: '10rem' }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
