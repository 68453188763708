import { ContainerAttachToTicket } from 'components/notes';
import { TicketActionItemDialogEdit } from 'components/ticket-action-item-containers';
import { useAppDispatch, useAppSelector, useEffectNotifyError } from 'hooks';
import { memo, useCallback } from 'react';
import { apiNotes, IBoardNote } from 'services/notes';
import { ITicketActionItem } from 'services/ticket-action-items';
import { actionsNotes, selectAttachToTicket } from 'store/notes';

const useDoneMutation = apiNotes.useDoneNoteMutation;

interface Props {
  data: IBoardNote;
}

export const AttachToTicketConfirm = memo<Props>(({ data }) => {
  const dispatch = useAppDispatch();

  const onClose = useCallback(() => {
    dispatch(actionsNotes.setAttachToTicket(null));
  }, [dispatch]);

  const [performDone, resultDone] = useDoneMutation();
  useEffectNotifyError(resultDone.error);

  const onDone = useCallback(
    (ticketActionItem: Pick<ITicketActionItem, 'id'>) => {
      performDone({ id: data.id, ticketActionItemID: ticketActionItem.id }).finally(onClose);
    },
    [data, performDone, onClose],
  );

  return data.itemKey ? (
    <TicketActionItemDialogEdit itemKey={data.itemKey} onClose={onClose} onDone={onClose} />
  ) : (
    <ContainerAttachToTicket note={data} onClose={onClose} onDone={onDone} />
  );
});

export const AttachToTicket = memo(() => {
  const data = useAppSelector(selectAttachToTicket);
  return data ? <AttachToTicketConfirm data={data} /> : null;
});
