import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useOpen, useTranslate } from 'hooks';
import { CardLayout } from 'pages/notes/components/card-layout';
import { DateRange } from 'pages/notes/components/date-range';
import { memo, useCallback, useMemo } from 'react';
import variables from 'styles/config.scss';
import { useValueQuery } from '../../../../../../hooks/use-value-query';
import { QUERY_NAME } from '../../../../helpers';
import { CardDotsControl } from '../../../view-grid/components/card-dots-control';
import { CalendarNoteType } from '../data-provider';
import { NoteDescriptionTooltip } from '../note-description-tooltip';
import style from './index.module.scss';

interface Props {
  data: CalendarNoteType;
}

export const DueDateNotesGroupNote = memo<Props>(({ data }) => {
  const { tp } = useTranslate();
  const customer = useMemo(() => data?._customer?.title || tp('without-customer'), [data, tp]);
  const color = data?._customer?.color ? data?._customer?.color : variables.colorBlack;
  const { isOpen, onOpen, onClose } = useOpen();

  const { onChange: onChangeEdit } = useValueQuery({ name: QUERY_NAME });
  const onEdit = useCallback(() => {
    onChangeEdit(data.id);
  }, [data.id, onChangeEdit]);

  return (
    <NoteDescriptionTooltip data={data}>
      <CardLayout
        color={color}
        progress={100}
        title={customer}
        isOpen={isOpen}
        slotControls={
          <CardDotsControl data={data} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        }
        className={clsx(style.root, data?.description && style.hasContent)}
        onDoubleClick={onEdit}
      >
        <DateRange
          entryDate={data.entryDate}
          dueDate={data.dueDate}
          archiveDate={data._archiveDate}
        />
        <Typography component={'div'} lineHeight={1.3} className={style.description}>
          {data.title || ''}
        </Typography>
      </CardLayout>
    </NoteDescriptionTooltip>
  );
});
