import { Identifier } from 'dnd-core';
import { useAppDispatch, useMergeRef } from 'hooks';
import { useElementSize } from 'hooks/use-element-size';
import { throttle } from 'lodash-es';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { actionsNotes } from 'store/notes';
import { DragItem, DRAG_TYPES, DropResult } from './../helpers';

type Move = {
  id: string;
  index: number;
};

interface Props {
  id: string;
  index: number;
  orderIndex: number;
  children: React.ReactNode;
}
export const DraggableWrapper: React.FC<Props> = ({ id, orderIndex, children, index }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const onMove = useMemo(
    () =>
      throttle((input: { from: Move; to: Move }) => {
        dispatch(actionsNotes.reorder(input));
      }, 200),
    [dispatch],
  );

  const [{ handlerId }, drop] = useDrop<DragItem, DropResult, { handlerId: Identifier | null }>({
    accept: DRAG_TYPES.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const from = { id: item.id, index: item.index };
      const to = { id, index: orderIndex };

      if (from.index === to.index) return;
      if (from.id === to.id) return;

      if (!ref.current) return;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;

      const toRect = ref.current.getBoundingClientRect();
      const fromRect = item.getSize();

      const mouseCoords = { x: clientOffset.x - toRect.left, y: clientOffset.y - toRect.top };
      const centerCoords = { x: toRect.width / 2, y: toRect.height / 2 };

      // from left
      if (from.index < to.index && fromRect.left < toRect.left) {
        if (centerCoords.x > mouseCoords.x) return;
      }
      // from top
      if (from.index < to.index && fromRect.top < toRect.top) {
        if (centerCoords.y > mouseCoords.y) return;
      }

      // from right
      if (from.index > to.index && fromRect.left > toRect.left) {
        if (centerCoords.x < mouseCoords.x) return;
      }
      // from bottom
      if (from.index > to.index && fromRect.top > toRect.top) {
        if (centerCoords.y < mouseCoords.y) return;
      }

      // Time to actually perform the action

      onMove({ from, to });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // item.index = to.index;
    },
  });

  const [{ isDragging }, drag] = useDrag<DragItem, DropResult, { isDragging: boolean }>({
    type: DRAG_TYPES.CARD,
    item: () => {
      const el = ref.current;

      const rect = el ? el.getBoundingClientRect() : { left: 0, top: 0, width: 0, height: 0 };

      return { id, index: orderIndex, getSize: () => rect };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const [refSize, size] = useElementSize();

  useEffect(() => {
    dispatch(actionsNotes.setSize({ id, size }));
  }, [size, id, dispatch]);

  const mainRef = useMergeRef(ref, refSize);

  return (
    <div ref={mainRef} style={{ opacity, padding: '0.5rem' }} data-handler-id={handlerId}>
      {children}
    </div>
  );
};
