interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}
export const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};
