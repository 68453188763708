import clsx from 'clsx';
import React, { memo, useMemo } from 'react';
import { MarksContextProps } from '../marks-provider';
import style from './index.module.scss';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  ['data-index']: number;
  hover: boolean;
  setHover: (hover: boolean) => void;
  ownerState: {
    marks: MarksContextProps['marks'];
    value: [number, number];
    dragging: boolean;
  };
}

export const NoteThumb = memo<Props>(
  ({ className, hover, setHover, style: thumbPosition, ...rest }) => {
    const { left, isStart, isEnd } = useMemo(
      () => ({
        left: rest['data-index'] === 0,
        isStart: rest['data-index'] === 0 && rest.ownerState.value[0] === 0,
        isEnd:
          rest['data-index'] === 1 &&
          rest.ownerState.value[1] ===
            rest.ownerState.marks[rest.ownerState.marks.length - 1].value,
      }),
      [rest],
    );
    return (
      <div
        {...rest}
        style={thumbPosition}
        className={clsx(
          style.root,
          rest.ownerState.dragging && style.dragging,
          left ? style.left : style.right,
          isStart && style.start,
          isEnd && style.end,
          hover && style.hover,
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    );
  },
);
