import { DialogConfirm } from 'components/dialog-confirm';
import { useAppDispatch, useAppSelector, useEffectNotifyError } from 'hooks';
import { memo, useCallback } from 'react';
import { apiNotes, IBoardNote } from 'services/notes';
import { actionsNotes, selectArchive } from 'store/notes';
import { composeFunctions } from 'utils/other';

const useUpdateMutation = apiNotes.useToggleArchiveNoteMutation;

interface Props {
  data: IBoardNote;
}

export const ArchiveNodeConfirm = memo<Props>(({ data }) => {
  const dispatch = useAppDispatch();

  const confirmTitle = data.isActive ? 'archive' : 'restore';
  const confirmMessage = data.isActive ? 'archive-confirm' : 'restore-confirm';

  const [update, resultUpdate] = useUpdateMutation();
  useEffectNotifyError(resultUpdate.error);

  const onClose = useCallback(() => {
    dispatch(actionsNotes.setArchive(null));
  }, [dispatch]);
  const onToggle = useCallback(() => {
    update({ id: data.id, value: Boolean(data.isActive) }).finally(onClose);
  }, [update, data, onClose]);

  return (
    <DialogConfirm
      title={confirmTitle}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={composeFunctions(onClose, onToggle)}
    >
      {confirmMessage}
    </DialogConfirm>
  );
});

export const ArchiveNode = memo(() => {
  const data = useAppSelector(selectArchive);
  return data ? <ArchiveNodeConfirm data={data} /> : null;
});
