import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import clsx from 'clsx';
import { addDays, startOfDay, subDays } from 'date-fns';
import { useEffectNotifyError } from 'hooks';
import React, {
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { apiNotes } from 'services/notes';
import variables from 'styles/config.scss';
import { NoteType } from '../../index';
import { MarksContextProps, useMarks } from '../marks-provider';
import { Note } from '../note';
import { NoteThumb } from '../note-thumb';
import style from './index.module.scss';

const usePatchMutation = apiNotes.usePatchNoteDatesMutation;

interface Props {
  data: NoteType;
}

export const NoteRow = memo<Props>(({ data }) => {
  const [value, setValue] = useState<number[]>([0, 0]);
  const isInteractingRef = useRef(false);

  const { marks, dates, width } = useMarks();
  const { start, end } = useMemo(
    () => ({
      start: startOfDay(data.entryDate).getTime(),
      end: startOfDay(data.dueDate).getTime(),
    }),
    [data.entryDate, data.dueDate],
  );

  const [patchItem, resultPatch] = usePatchMutation();
  useEffectNotifyError(resultPatch.error);

  const saveChanges = useCallback(
    (values: number[], marks: MarksContextProps['marks'], dates: MarksContextProps['dates']) => {
      const getDateFromValue = (value: number, isEnd: boolean) => {
        if (value === marks[0].value) {
          return subDays(dates[0], 1);
        }
        if (value === marks[marks.length - 1].value) {
          return addDays(dates[dates.length - 1], 1);
        }
        const index = marks.findIndex((mark) => mark.value === value);
        return new Date(dates[isEnd ? index - 2 : index - 1]);
      };

      const newStart = getDateFromValue(values[0], false);
      const newEnd = getDateFromValue(values[1], true);

      const currentStart = startOfDay(new Date(data.entryDate)).getTime();
      const currentEnd = startOfDay(new Date(data.dueDate)).getTime();

      if (newStart.getTime() !== currentStart || newEnd.getTime() !== currentEnd) {
        patchItem({
          id: data.id,
          entryDate: newStart.toISOString(),
          dueDate: newEnd.toISOString(),
        });
      }
    },
    [patchItem, data],
  );

  const handleChange = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (event.type !== 'mousemove' && event.type !== 'touchmove') {
        return;
      }

      if (!Array.isArray(newValue)) {
        return;
      }

      isInteractingRef.current = true;

      const getClosestMark = (value: number) => {
        return marks.reduce((prev, curr) => {
          return Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev;
        }).value;
      };

      let newValues = newValue.map(getClosestMark) as number[];

      if (activeThumb === 1) {
        if (newValues[1] < marks[2].value) {
          newValues[1] = marks[2].value;
        }
      } else {
        if (newValues[0] > marks[marks.length - 3].value) {
          newValues[0] = marks[marks.length - 3].value;
        }
      }

      if (newValues[1] <= newValues[0]) {
        if (activeThumb === 0) {
          const nextMark = marks.find((mark) => mark.value > newValues[0]);
          if (nextMark) {
            newValues = [newValues[0], nextMark.value];
          }
        } else {
          const prevMark = marks
            .slice()
            .reverse()
            .find((mark) => mark.value < newValues[1]);
          if (prevMark) {
            newValues = [prevMark.value, newValues[1]];
          }
        }
      }

      setValue(newValues);
    },
    [marks, setValue],
  );

  const onUpdateValue = useCallback(() => {
    if (isInteractingRef.current) return;

    const entryIndex = dates.findIndex((date) => date === start);
    const dueIndex = dates.findIndex((date) => date === end);

    setValue([
      entryIndex < 0 || start < dates[0] ? marks[0].value : marks[entryIndex + 1].value,
      dueIndex < 0 || end > dates[dates.length - 1]
        ? marks[marks.length - 1].value
        : marks[dueIndex + 2].value,
    ]);
  }, [start, end, dates, marks, setValue]);

  useEffect(onUpdateValue, [onUpdateValue]);

  const handleChangeCommitted = useCallback(
    (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
      isInteractingRef.current = false;
      if (Array.isArray(newValue)) {
        saveChanges(newValue, marks, dates);
      }
    },
    [saveChanges, marks, dates],
  );

  const borderColor = data?._customer?.color ? data?._customer.color : variables.colorBlack;
  const rootStyle = { '--color': borderColor } as React.CSSProperties;

  const [hover, setHover] = useState(false);

  return (
    <Box className={clsx(style.root, isInteractingRef.current && style.dragging)} style={rootStyle}>
      <Slider
        className={style.slider}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        disableSwap
        marks={marks}
        step={null}
        valueLabelDisplay="off"
        max={width}
        slots={{
          rail: () => null,
          mark: () => null,
          track: (props) => <Note {...props} data={data} setHover={setHover} />,
          thumb: (props) => <NoteThumb {...props} hover={hover} setHover={setHover} />,
        }}
      />
    </Box>
  );
});
