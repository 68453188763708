import { createSelector } from '@reduxjs/toolkit';
import { addDays, endOfDay, isWithinInterval, startOfDay } from 'date-fns';
import { sortBy } from 'lodash-es';
import { selectSourceCustomersAllMap } from 'services/customers';
import { NOTE_CUSTOMER } from 'services/notes';
import { convertToDate, getCalendarMonthBounds, isAcrossIntervals } from 'utils/dates';
import { getSearchFilter } from 'utils/front-filters';
import { selectAccountUser } from '../auth';
import { AppState } from '../index';
import { isNoteCustomerID, selectNotesLayoutFilters } from '../notes-layout';
import { notesDueDateToRange, NOTES_DUE_DATE, NOTES_VIEW } from './helpers';

const selectState = (state: AppState) => state.notes;

const selectData = createSelector(selectState, ({ data }) => data);

export const selectView = createSelector(selectState, ({ view }) => view);
export const selectArchive = createSelector(selectState, ({ archive }) => archive);
export const selectAttachToTicket = createSelector(
  selectState,
  ({ attachToTicket }) => attachToTicket,
);

export const selectAddNewNote = createSelector(selectState, ({ addNewNote }) => addNewNote);

export const selectGrid = createSelector(selectState, ({ [NOTES_VIEW.GRID]: data }) => data);
export const selectGridFilters = createSelector(selectGrid, ({ filters }) => filters);
export const selectGridOrders = createSelector(selectGrid, ({ orders }) => orders);
export const selectGridSizes = createSelector(selectGrid, ({ sizes }) => sizes);

export const selectTimeLine = createSelector(
  selectState,
  ({ [NOTES_VIEW.TIMELINE]: data }) => data,
);
export const selectTimeLineFilters = createSelector(selectTimeLine, ({ filters }) => filters);

export const selectCalendar = createSelector(
  selectState,
  ({ [NOTES_VIEW.CALENDAR]: data }) => data,
);
export const selectCalendarFilters = createSelector(selectCalendar, ({ filters }) => filters);

export const selectCalendarSelectedDate = createSelector(
  selectCalendar,
  ({ selectedDate }) => selectedDate,
);

export const selectNotesStatuses = createSelector(
  selectState,
  ({ isLoading, isMounted, isOpenExpiredManager }) => {
    return { isLoading, isMounted, isOpenExpiredManager };
  },
);

export const selectDataFull = createSelector(
  selectData,
  selectSourceCustomersAllMap,
  selectAccountUser,
  (rows, mapCustomer, user) => {
    return rows.map((row) => {
      return {
        ...row,
        _customer: row.customerID ? mapCustomer[row.customerID] : null,
        _archiveDate: addDays(convertToDate(row.dueDate), 0),
      };
    });
  },
);

export const selectDataExpired = createSelector(selectDataFull, (rows) => {
  return rows.filter((row) => {
    return startOfDay(new Date()) > startOfDay(convertToDate(row.dueDate));
  });
});

export const selectDataFullFilteredForGrid = createSelector(
  selectDataFull,
  selectGridFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    const dueDateRange = notesDueDateToRange(filters.dates);
    const today = startOfDay(new Date()).getTime();

    return rows.filter((row) => {
      if (layoutFilters.customer) {
        if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER && row.customerID) {
          return false;
        }
        if (isNoteCustomerID(layoutFilters.customer) && row.customerID !== layoutFilters.customer) {
          return false;
        }
      }

      if (filters.search) {
        const _row = {
          title: row.title,
          description: row.description,
          customer: row._customer?.title,
        };
        if (
          !getSearchFilter<typeof _row>({
            value: filters.search,
            fields: ['title', 'customer', 'description'],
          })(_row)
        ) {
          return false;
        }
      }

      if (
        filters.dates === NOTES_DUE_DATE.PAST_DUE_DATE &&
        startOfDay(convertToDate(row.dueDate)).getTime() > today
      ) {
        return false;
      }
      if (
        filters.dates === NOTES_DUE_DATE.TODAY &&
        startOfDay(convertToDate(row.dueDate)).getTime() !== today
      ) {
        return false;
      }
      if (
        filters.dates === NOTES_DUE_DATE.WORKING_WEEK &&
        !isWithinInterval(startOfDay(convertToDate(row.dueDate)), {
          start: dueDateRange[0],
          end: dueDateRange[1],
        })
      ) {
        return false;
      }

      return true;
    });
  },
);

export const selectGridRows = createSelector(
  selectDataFullFilteredForGrid,
  selectGridOrders,
  selectGridSizes,
  (rows, orders, sizes) => {
    return rows
      .map((item) => {
        return {
          ...item,
          _orderIndex: orders[item.id] || item.rowIndex || 0,
          _size: sizes[item.id] || { width: 0, height: 0 },
        };
      })
      .sort((a, b) => a._orderIndex - b._orderIndex);
  },
);

export const selectDataFullFilteredForCalendar = createSelector(
  selectDataFull,
  selectCalendarFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    const adjustedDates = getCalendarMonthBounds(filters.dates[0]);
    return sortBy(
      rows.filter((row) => {
        if (
          !row.entryDate ||
          isNaN(new Date(row.entryDate).getTime()) ||
          !row.dueDate ||
          isNaN(new Date(row.dueDate).getTime())
        ) {
          return false;
        }

        if (layoutFilters.customer) {
          if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER && row.customerID) {
            return false;
          }
          if (
            isNoteCustomerID(layoutFilters.customer) &&
            row.customerID !== layoutFilters.customer
          ) {
            return false;
          }
        }

        if (filters.search) {
          const _row = {
            title: row.title,
            description: row.description,
            customer: row._customer?.title,
          };
          if (
            !getSearchFilter<typeof _row>({
              value: filters.search,
              fields: ['title', 'customer', 'description'],
            })(_row)
          ) {
            return false;
          }
        }

        if (!isAcrossIntervals([row.entryDate, row.dueDate], adjustedDates)) {
          return false;
        }

        return true;
      }),
      ['dueDate'],
    );
  },
);

export const selectDataFullFilteredForCalendarToday = createSelector(
  selectDataFull,
  selectCalendarFilters,
  selectNotesLayoutFilters,
  selectCalendarSelectedDate,
  (rows, filters, layoutFilters, selectedDate) => {
    const currentDateFormated = startOfDay(selectedDate);
    return sortBy(
      rows.filter((row) => {
        if (
          !row.entryDate ||
          isNaN(new Date(row.entryDate).getTime()) ||
          !row.dueDate ||
          isNaN(new Date(row.dueDate).getTime())
        ) {
          return false;
        }

        if (layoutFilters.customer) {
          if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER && row.customerID) {
            return false;
          }
          if (
            isNoteCustomerID(layoutFilters.customer) &&
            row.customerID !== layoutFilters.customer
          ) {
            return false;
          }
        }

        if (filters.search) {
          const _row = {
            title: row.title,
            description: row.description,
            customer: row._customer?.title,
          };
          if (
            !getSearchFilter<typeof _row>({
              value: filters.search,
              fields: ['title', 'customer', 'description'],
            })(_row)
          ) {
            return false;
          }
        }

        if (
          !isWithinInterval(currentDateFormated, {
            start: startOfDay(row.entryDate),
            end: endOfDay(row.dueDate),
          })
        ) {
          return false;
        }

        return true;
      }),
      ['dueDate'],
    );
  },
);

export const selectDataFullFilteredForTimeLine = createSelector(
  selectDataFull,
  selectTimeLineFilters,
  selectNotesLayoutFilters,
  (rows, filters, layoutFilters) => {
    return sortBy(
      rows.filter((row) => {
        if (layoutFilters.customer) {
          if (layoutFilters.customer === NOTE_CUSTOMER.WITHOUT_CUSTOMER && row.customerID) {
            return false;
          }
          if (
            isNoteCustomerID(layoutFilters.customer) &&
            row.customerID !== layoutFilters.customer
          ) {
            return false;
          }
        }

        if (filters.search) {
          const _row = {
            title: row.title,
            description: row.description,
            customer: row._customer?.title,
          };
          if (
            !getSearchFilter<typeof _row>({
              value: filters.search,
              fields: ['title', 'customer', 'description'],
            })(_row)
          ) {
            return false;
          }
        }

        if (row.entryDate && row.dueDate) {
          if (!isAcrossIntervals([row.entryDate, row.dueDate], filters.dates)) {
            return false;
          }
        }

        return true;
      }),
      ['dueDate'],
    );
  },
);
