import { BoxProps, Typography } from '@mui/material';
import { BaseCommentDescription } from 'components/tickets/components/base-comments/components/comment-description';
import { startOfDay } from 'date-fns';
import { useOpen } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import { CardLayout } from 'pages/notes/components/card-layout';
import { DateRange } from 'pages/notes/components/date-range';
import { NoteDescriptionTooltip } from 'pages/notes/components/view-calendar/components/note-description-tooltip';
import { CardDotsControl } from 'pages/notes/components/view-grid/components/card-dots-control';
import { QUERY_NAME } from 'pages/notes/helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { IBoardNote } from 'services/notes';
import variables from 'styles/config.scss';
import { convertToDate } from 'utils/dates';
import { handleEventStopAndPrevent } from 'utils/handlers';
import { interpolation } from 'utils/math';
import { composeFunctions } from 'utils/other';
import { Unset } from 'utils/types';

interface Props extends BoxProps {
  data: IBoardNote;
  customer: { title: Unset; color: Unset } | null;
  archiveDate: Date;
}

export const Card: React.FC<Props> = ({ data, archiveDate, customer }) => {
  const { onChange } = useValueQuery({ name: QUERY_NAME });
  const onEdit = () => onChange(data.id);
  const [progress, setProgress] = useState(100);

  const { entryDate, dueDate } = useMemo(() => {
    const dueDate = convertToDate(data.dueDate);
    const entryDate = convertToDate(data.entryDate);

    return { dueDate, entryDate };
  }, [data.dueDate, data.entryDate]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      let x0 = entryDate.getTime();
      let x = new Date().getTime();
      let x1 = archiveDate.getTime();

      let percent = interpolation({ x0, x, x1, y0: 0, y1: 100 });
      setProgress(Math.max(100 - percent, 0));
    }, 2000);
    return () => {
      clearTimeout(timerID);
    };
  }, [entryDate, archiveDate]);

  const isExpired = useMemo(
    () => startOfDay(new Date()) > startOfDay(convertToDate(dueDate)),
    [dueDate],
  );

  const color = customer?.color ? customer.color : variables.colorBlack;
  const { isOpen, onOpen, onClose } = useOpen();

  return (
    <CardLayout
      title={customer?.title}
      color={color}
      progress={progress}
      isOpen={isOpen}
      isExpired={isExpired}
      onDoubleClick={composeFunctions(handleEventStopAndPrevent, onEdit)}
      slotControls={
        <CardDotsControl data={data} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      }
    >
      <DateRange entryDate={data.entryDate} dueDate={data.dueDate} archiveDate={archiveDate} />
      {data.title && (
        <Typography variant={'subtitle1'} component={'h4'} mb={0.6}>
          {data.title}
        </Typography>
      )}
      <NoteDescriptionTooltip data={data}>
        <BaseCommentDescription
          description={data.description}
          images={data.images}
          sx={{
            cursor: 'help',
          }}
        />
      </NoteDescriptionTooltip>
    </CardLayout>
  );
};
