import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, Stack, Typography } from '@mui/material';
import { Item, MenuButton } from 'components/menu-button';
import { useAppDispatch, useTranslate } from 'hooks';
import { useValueQuery } from 'hooks/use-value-query';
import { QUERY_NAME } from 'pages/notes/helpers';
import React, { useCallback, useMemo } from 'react';
import { IBoardNote } from 'services/notes';
import { actionsNotes } from 'store/notes';

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  data: IBoardNote;
}

export const CardDotsControl: React.FC<Props> = ({ data, isOpen, onClose, onOpen }) => {
  const { tp } = useTranslate();

  const dispatch = useAppDispatch();

  const onClickAttachToTicket = useCallback(() => {
    dispatch(actionsNotes.setAttachToTicket(data));
  }, [dispatch, data]);

  const IconoAttachToTicket = data.itemKey ? RemoveRedEyeIcon : AssignmentIcon;
  const titleAttachToTicket = useMemo(
    () => tp(data.itemKey ? 'view-ticket-action-item' : 'create-ticket-action-item'),
    [data.itemKey, tp],
  );

  const { onChange: onChangeEdit } = useValueQuery({ name: QUERY_NAME });
  const onEdit = useCallback(() => {
    onChangeEdit(data.id);
  }, [data.id, onChangeEdit]);

  const IconArchive = !data.isActive ? RestoreIcon : ArchiveOutlinedIcon;
  const titleArchive = !data.isActive ? tp('restore') : tp('archive');

  const onClickArchive = useCallback(() => {
    dispatch(actionsNotes.setArchive(data));
  }, [dispatch, data]);

  const items = useMemo<Item[]>(
    () => [
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <EditIcon
              color={'silver'}
              fontSize={'inherit'}
              sx={{
                fontSize: 20,
              }}
            />
            <Typography>{tp('edit')}</Typography>
          </Stack>
        ),
        onClick: onEdit,
      },
      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <IconoAttachToTicket
              color={'silver'}
              sx={{
                fontSize: 20,
              }}
            />
            <Typography>{titleAttachToTicket}</Typography>
          </Stack>
        ),
        onClick: onClickAttachToTicket,
      },

      {
        title: (
          <Stack direction={'row'} spacing={0.8} alignItems={'center'}>
            <IconArchive
              color={'error'}
              sx={{
                fontSize: 20,
              }}
            />
            <Typography color={'error'}>{titleArchive}</Typography>
          </Stack>
        ),
        onClick: onClickArchive,
      },
    ],
    [
      onClickAttachToTicket,
      IconoAttachToTicket,
      titleAttachToTicket,
      onEdit,
      IconArchive,
      onClickArchive,
      titleArchive,
      tp,
    ],
  );

  return (
    <MenuButton
      items={items}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isPreventDefault={false}
    >
      <IconButton size={'small'}>
        <MoreVertIcon color={'silver'} />
      </IconButton>
    </MenuButton>
  );
};
